import { Action, createReducer, on } from '@ngrx/store';

import * as ImportFromPackageActions from './import-from-package.actions';
import { ImportFromPackageState, initialState } from './import-from-package.state';

export const importFromPackageFeatureKey = 'importFromPackage';

const importFromPackageReducer = createReducer<ImportFromPackageState>(
  initialState,
  on(ImportFromPackageActions.GetPackagesByProductFamilySuccessAction,
    (state: ImportFromPackageState, {packages}) => {
      return {
        ...state,
        packages
      };
    }),
  on(ImportFromPackageActions.GetImportPreviewAction, (state: ImportFromPackageState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ImportFromPackageActions.GetImportPreviewSuccessAction,
    (state: ImportFromPackageState, {regions, unassignedCountries}) => {
      return {
        ...state,
        regions,
        unassignedCountries,
        loading: false
      };
    })
);

export function ImportFromPackageReducer(state: ImportFromPackageState | undefined,
  action: Action): ImportFromPackageState {
  return importFromPackageReducer(state, action);
}
