import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HTTP_STATUS_CODES } from '@bbraun/toolguide/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as ImportFstActions from './import-fst.actions';

@Injectable()
export class ImportFstEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly packagesService: PackagesService
  ) {
  }

  getImportFstPreview$ = createEffect(() => this.actions$.pipe(
    ofType(ImportFstActions.GetFstPreviewAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.getFstPreview(action.packageId, action.regionId, action.template).pipe(
        map((payload: any) => {
          return ImportFstActions.GetFstPreviewSuccessAction({fstsPreview: payload});
        }),
        catchError((error: HttpErrorResponse) => {
          if (error && error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
            return of(ImportFstActions.GetFstPreviewErrorAction());
          } else {
            return of(new GenericErrorAction(error));
          }
        })
      )
    )
  ));
}
