import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RegionDetails } from '../../modules/shared/interfaces/region/region-details.interface';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as EditRegionDetailsActions from './edit-region-details.actions';

@Injectable()
export class EditRegionDetailsEffects {
  constructor(private readonly actions$: Actions,
              private readonly packagesService: PackagesService,
              private readonly router: Router
  ) { }

  loadRegionDetails$ = createEffect(() => this.actions$.pipe(
    ofType(EditRegionDetailsActions.LoadRegionDetailsAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.getRegionDetails(payload.packageId, payload.regionId).pipe(
        map((response: RegionDetails) => {
          return EditRegionDetailsActions.LoadRegionDetailsSuccessAction({regionDetails: response});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      ))
  ));

  updateRegion$ = createEffect(() => this.actions$.pipe(
    ofType(EditRegionDetailsActions.UpdateRegionDetailsAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.updateRegionDetails(payload.packageId, payload.regionId, payload.updatedRegion).pipe(
        map(() => {
          this.router.navigate(['package-details', payload.packageId]);

          return EditRegionDetailsActions.UpdateRegionDetailsSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      ))
  ));
}
