import { CustomerAutomaticDistribution } from '../../interfaces/customer/customer-automatic-distribution';
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';

export class PagingResponseCustomersAutomaticDistributionModel
implements PagingResponse<CustomerAutomaticDistribution> {
  content: Array<CustomerAutomaticDistribution>;
  pageable: {
        sort: {
            unsorted: boolean;
            sorted: boolean;
            empty: boolean;
        };
        offset: number;
        pageSize: number;
        pageNumber: number;
        paged: boolean;
        unpaged: boolean;
    };
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;

  constructor(
    content?: Array<CustomerAutomaticDistribution>,
    pageable?: {
            sort: {
                unsorted: null;
                sorted: null;
                empty: null;
            };
            offset: 0;
            pageSize: 0;
            pageNumber: 0;
            paged: null;
            unpaged: null;
        },
    totalPages?: 0,
    totalElements?: 0,
    last?: null,
    first?: null,
    sort?: {
            sorted: null;
            unsorted: null;
            empty: null;
        },
    numberOfElements?: 0,
    size?: 0,
    number?: 0,
    empty?: null
  ) {
    this.content = content ? content : [];
    this.pageable = pageable ? pageable : null;
    this.totalPages = totalPages ? totalPages : 0;
    this.totalElements = totalElements ? totalElements : 0;
    this.last = last ? last : null;
    this.first = first ? first : null;
    this.sort = sort ? sort : null;
    this.numberOfElements = numberOfElements ? numberOfElements : null;
    this.size = size ? size : null;
    this.number = number ? number : null;
    this.empty = empty ? empty : null;
  }
}
