import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { RedirectEndpointComponent } from './modules/auth/redirect-endpoint/redirect-endpoint.component';
import { SoftwareDistributionAdminGuard } from './modules/global-user-replacement/guards/software-distribution-admin.guard';
import { NewPackageGuard } from './modules/new-package/guards/new-package.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'overview', loadChildren: () => import('./modules/home/overview/overview.module').then((m) => m.OverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inbox', loadChildren: () => import('./modules/home/inbox/inbox.module').then((m) => m.InboxModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archive', loadChildren: () => import('./modules/home/archive/archive.module').then((m) => m.ArchiveModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'changes', loadChildren: () => import('./modules/home/changes/changes.module').then((m) => m.ChangesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-package',
    loadChildren: () => import('./modules/new-package/new-package.module').then((m) => m.NewPackageModule),
    canActivate: [AuthGuard, NewPackageGuard]
  },
  {
    path: 'package-details/:id/edit-region-details/:regionId',
    loadChildren: () => import('./modules/edit-region-details/edit-region-details.module').then((m) => m.EditRegionDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'package-details/:id/import-from-package/:productFamily',
    loadChildren: () => import('./modules/import-from-package/import-from-package.module').then((m) => m.ImportFromPackageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'package-details/:id/import-fst/:regionId',
    loadChildren: () => import('./modules/import-fst/import-fst.module').then((m) => m.ImportFSTModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'package-details/:id',
    loadChildren: () => import('./modules/package-details/package-details.module').then((m) => m.PackageDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'revision-details/:id',
    loadChildren: () => import('./modules/package-details/package-details.module').then((m) => m.PackageDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'global-user-replacement',
    loadChildren: () => import('./modules/global-user-replacement/global-user-replacement.module').then((m) => m.GlobalUserReplacementModule),
    canActivate: [AuthGuard, SoftwareDistributionAdminGuard]
  },
  {
    path: 'audit-exports',
    loadChildren: () => import('./modules/audit-exports//audit-exports.module').then((m) => m.AuditExportsModule),
    canActivate: [AuthGuard, SoftwareDistributionAdminGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/shared/components/error-page/error-page.module').then((m) => m.ErrorPageModule),
    canActivate: [AuthGuard]
  },
  {path: 'login', component: LoginComponent},
  {path: 'auth/callback', component: RedirectEndpointComponent},
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // The initial navigation is not performed. The location listener is set up before the root component gets created
      // allowing the auth logic to be initialized.
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy',
      // Restores the previous scroll position on backward navigation, else sets the position to the anchor if one is
      // provided, or sets the scroll position to [0, 0] (forward navigation).
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
