import { TableDataObject } from 'src/app/modules/shared/interfaces/table-data-object/table-data-object.interface';

import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PagingResponseDataTableObjectModel } from '../../modules/shared/models/paging/paging-response-data-table-objects.model';

export interface ChangesState {
  packages: PagingResponse<TableDataObject>;
  loading: boolean;
}

export const initialState: ChangesState = {
  packages: new PagingResponseDataTableObjectModel(),
  loading: true
};
