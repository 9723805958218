import { Component, OnInit } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  constructor(private readonly oauthService: OAuthService) {}

  ngOnInit(): void {
    this.oauthService.initCodeFlow();
  }
}
