import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { StorageService } from '../../shared/services/storage/storage.service';
import { INITIAL_REDIRECT_STATE } from '../auth.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
        private readonly router: Router,
        private readonly oauthService: OAuthService,
        private readonly storageService: StorageService
  ) {}

  canActivate(): boolean {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return true;
    } else {
      this.storageService.
        setItem(INITIAL_REDIRECT_STATE, window.location.pathname + window.location.search, localStorage);
      this.router.navigate(['/login']);
      return false;
    }
  }
}
