import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import {
  EXCLUDE_CURRENT_USER_PARAM,
  EXCLUDE_USERNAMES_HEADER,
  LDAP_GROUPS_HEADER,
  PAGE,
  SEARCH_PARAM, SIZE, USERNAMES_HEADER
} from '../../constants/http/http.constants';
import { UserAssignments } from '../../interfaces/global-user-replacement/user-assignments.interface';
import { LdapUser } from '../../interfaces/ldap-user/ldap-user.interface';
import { LdapUserHttpRequest } from "../../interfaces/ldap-user/ldap-user-http-request";
import { PagingResponse } from "../../interfaces/paging/paging-response.interface";
import { BaseHttpService } from '../http/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseHttpService {

  /**
   * Retrieves all users or users filtered by the optional search parameters.
   *
   * @param term - (optional) flag if current user should be excluded from the result
   * @param excludeCurrent - (optional) flag if current user should be excluded from the result
   * @param ldapGroups - (optional) filter users by their ldap group roles
   */
  getUsers(term?: string, excludeCurrent?: boolean, ldapGroups?: Array<number>): Observable<Array<LdapUser>> {
    return this.appendParam(SEARCH_PARAM, term)
      .appendParam(EXCLUDE_CURRENT_USER_PARAM, excludeCurrent)
      .appendHeader(LDAP_GROUPS_HEADER, ldapGroups)
      .get<Array<LdapUser>>(`${environment.apiUrl}/users`);
  }

  /**
   * Retrieves users by username. The endpoint has the possibility to retrieve all users filtered by the searched username or retrieve all excluding the currently logged in user.
   */
  retrieveUsers(userHttpRequest: LdapUserHttpRequest): Observable<PagingResponse<LdapUser>> {
    return this.appendParam(SEARCH_PARAM, userHttpRequest.searchParameter)
      .appendParam(PAGE, userHttpRequest.page.toString())
      .appendParam(SIZE, userHttpRequest.size.toString())
      .appendHeader(USERNAMES_HEADER, userHttpRequest.usernames)
      .appendHeader(LDAP_GROUPS_HEADER, userHttpRequest.ldapGroups)
      .appendHeader(EXCLUDE_USERNAMES_HEADER, userHttpRequest.excludeUsernames)
      .get<PagingResponse<LdapUser>>(`${environment.apiV2Url}/users`);
  }

  getUserAssignments(userName: string): Observable<Array<UserAssignments>> {
    return this.get<Array<UserAssignments>>(`${environment.apiUrl}/users/${userName}/assignments`);
  }
}
