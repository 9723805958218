import { FstPreview } from '../../modules/shared/interfaces/import-fst/fst-preview.interface';
import { LdapUser } from '../../modules/shared/interfaces/ldap-user/ldap-user.interface';

export interface ImportFstState {
    fstsPreview: Array<FstPreview>;
    fstsPreviewFiltered: Array<LdapUser>;
    regionId: number;
    loading: boolean;
}

export const initialState: ImportFstState = {
  fstsPreview: [],
  fstsPreviewFiltered: [],
  regionId: null,
  loading: false
};
