export const INSTRUCTION_FOR_USE_EN = 'Instructions for use (EN)';

export const SOFTWARE_BINARY = 'Software binary';

export const TABLE_SELECTION_MODEL = {
  allowMultiSelect: true,
  initialSelection: []
};

export const UPLOAD_CHUNK_RETRIES = 2;

// 100 MB
export const FILE_CHUNK_SIZE = 104857600;

export const DELAY_LOAD_FILE_LOCATION = 3000;
