import { LdapUser } from "../../modules/shared/interfaces/ldap-user/ldap-user.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PagingResponseUsersModel } from "../../modules/shared/models/paging/paging-response-users.model";
import { UserAssignments } from './../../modules/shared/interfaces/global-user-replacement/user-assignments.interface';

export interface GlobalUserReplacementState {
  users: PagingResponse<LdapUser>;
  userAssignments: Array<UserAssignments>;
  loading: boolean;
}

export const initialState: GlobalUserReplacementState = {
  users: new PagingResponseUsersModel(),
  userAssignments: [],
  loading: false
};
