import { Action, createReducer, on } from '@ngrx/store';

import * as PackageRegistrationsActions from './package-registrations.actions';
import { initialState, PackageRegistrationsState } from './package-registrations.state';

export const packageRegistrationsFeatureKey = 'packageRegistrations';

const packageRegistrationsReducer = createReducer<PackageRegistrationsState>(
  initialState,
  on(PackageRegistrationsActions.LoadCountriesSuccessAction, (state: PackageRegistrationsState, {payload}) => {
    return {
      ...state,
      countries: payload
    };
  }),
  on(PackageRegistrationsActions.LoadCERegionCountriesSuccessAction,
    (state: PackageRegistrationsState, {countries}) => {
      return {
        ...state,
        ceRegionCountries: countries
      };
    }),
  on(PackageRegistrationsActions.LoadFrenchBlockCountriesSuccessAction,
    (state: PackageRegistrationsState, {countries}) => {
      return {
        ...state,
        frenchBlockCountries: countries
      };
    }),
  on(PackageRegistrationsActions.CleanUpPackageRegistrationsAction, () => {
    return {
      countries: [],
      ceRegionCountries: [],
      frenchBlockCountries: []
    };
  })
);

export function PackageRegistrationsReducer(
  state: PackageRegistrationsState | undefined,
  action: Action): PackageRegistrationsState {
  return packageRegistrationsReducer(state, action);
}
