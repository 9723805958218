import { RegionDetails } from '../../modules/shared/interfaces/region/region-details.interface';
import { RegionDetailsModel } from '../../modules/shared/models/region/region-details.model';

export interface EditRegionDetailsState {
  regionDetails: RegionDetails;
}

export const initialState: EditRegionDetailsState = {
  regionDetails: new RegionDetailsModel()
};
