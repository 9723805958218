import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Product } from '../../interfaces/product/product.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private readonly http: HttpClient) {
  }

  get(searchParameter: string, productFamily: string): Observable<Array<Product>> {
    let params = new HttpParams({encoder: new CustomHttpParamEncoderService()});
    const searchParam = searchParameter ? searchParameter : '';
    params = params.append('searchParameter', searchParam);

    if (productFamily) {
      params = params.append('family', productFamily);
    }

    return this.http.get<Array<Product>>(`${environment.apiUrl}/products`, {params});
  }
}
