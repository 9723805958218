import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const AUTOCOMPLETE_MIN_LENGTH = 1;
export const AUTOCOMPLETE_DEBOUNCE_TIME = 300;

export const USERS_AUTOCOMPLETE_TERM_MIN_LENGTH = 3;

export const CUSTOMERS_AUTOCOMPLETE_PAGING_PROPERTIES = {
  page: 0,
  size: 5,
  sort: 'name,asc',
  term: ''
};

export const SEPARATOR_KEYS_CODES = [ENTER, COMMA];

export const AUTOCOMPLETE_DATA_OPERATIONS = {
  add: [],
  remove: []
};

export const mockAutocompleteValue = {
  displayValue: 'test',
  data: {
    id: 1,
    firstName: 'Cecile',
    lastName: 'Chiverstone',
    username: 'chivcebg'
  }
};

export const mockAutocompleteValuesUsers = [
  {
    displayValue: 'Cecile Chiverstone',
    data: {
      id: 1,
      firstName: 'Cecile',
      lastName: 'Chiverstone',
      username: 'chivcebg'
    }
  },
  {
    displayValue: 'Test Testov',
    data: {
      id: 2,
      firstName: 'Test',
      lastName: 'Testov',
      username: 'testbg'
    }
  }
];
