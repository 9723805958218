import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router} from '@angular/router';

import { environment } from '../../../../../environments/environment';

declare function ineum(command: string, ...data: Array<any>): void;

declare function instanaMonitoring(reportingUrl: string, apiKey: string): any;

@Injectable({
  providedIn: 'root'
})
export class InstanaService {

  constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
  ) {}

  /**
   * Trigger the Instana monitoring script with the reporting url and api key for the respective environment.
   */
  loadInstanaMonitoringScript(): void {
    if (environment.trackingUi.enable && environment.instanaApiKey) {
      instanaMonitoring(environment.trackingUi.reportingUrl, environment.instanaApiKey);
    }
  }

  /**
   * Trigger the Instana monitoring script for actions on the various SPA screens.
   */
  loadInstanaMonitoringRouterEvents(): void {
    if (!environment.trackingUi.enable) {
      return;
    }

    this.router.events.subscribe((event: Event) => {
      const componentName = (this.activatedRoute.component !== null) ? this.activatedRoute.component['name'] : 'default';

      if (event instanceof NavigationStart) {
        ineum('page', componentName);
        ineum('startSpaPageTransition');
      } else if (event instanceof NavigationEnd) {
        ineum('meta', 'component', componentName);
        ineum('endSpaPageTransition', {status: 'completed'});
      } else if (event instanceof NavigationError) {
        ineum('endSpaPageTransition', {
          status: 'error',
          url: window.location.href,
          explanation: event.error.toString()
        });
      } else if (event instanceof NavigationCancel) {
        ineum('endSpaPageTransition', {
          status: 'aborted',
          url: window.location.href,
          explanation: event.reason
        });
      }
    });
  }
}
