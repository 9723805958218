import { Action, createReducer, on } from '@ngrx/store';

import * as EditRegionActions from './edit-region-details.actions';
import { EditRegionDetailsState, initialState } from './edit-region-details.state';

export const editRegionDetailsFeatureKey = 'editRegionDetails';

const editRegionDetailsReducer = createReducer<EditRegionDetailsState>(
  initialState,
  on(EditRegionActions.LoadRegionDetailsSuccessAction, (state: EditRegionDetailsState, {regionDetails}) => {
    return {
      ...state,
      regionDetails
    };
  })
);

export function EditRegionDetailsReducer(state: EditRegionDetailsState | undefined,
  action: Action): EditRegionDetailsState {
  return editRegionDetailsReducer(state, action);
}
