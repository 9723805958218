import { createAction, props } from '@ngrx/store';

import { Country } from '../../../modules/shared/interfaces/country/country.interface';
import { FileType } from '../../../modules/shared/interfaces/file-type/file-type.interface';
import {
  ChunkUpload,
  RevisionChunkUpload
} from '../../../modules/shared/interfaces/package-file/chunk-upload.interface';
import { DownloadProgress } from '../../../modules/shared/interfaces/package-file/download-progress.interface';
import {
  PackageFile,
  PackageFileRevision
} from '../../../modules/shared/interfaces/package-file/package-file.interface';
import {
  UploadFileErrorResponse,
  UploadProgress
} from '../../../modules/shared/interfaces/package-file/upload-progress.interface';

export const LOAD_FILE_TYPES = '[PACKAGE-FILES] LOAD_FILE_TYPES';
export const LOAD_FILE_TYPES_SUCCESS = '[PACKAGE-FILES] LOAD_FILE_TYPES_SUCCESS';
export const GET_FILE_PREVIEW = '[PACKAGE-FILES] GET_FILE_PREVIEW';
export const GET_FILE_PREVIEW_SUCCESS = '[PACKAGE-FILES] GET_FILE_PREVIEW_SUCCESS';
export const FILE_PREVIEW_CLEANUP = '[PACKAGE-FILES] FILE_PREVIEW_CLEANUP';
export const UPLOAD_FILE = '[PACKAGE-FILES] UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = '[PACKAGE-FILES] UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_PROGRESS = '[PACKAGE-FILES] UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_ERROR = '[PACKAGE-FILES] UPLOAD_FILE_ERROR';
export const CANCEL_FILE_UPLOAD = '[PACKAGE-FILES] CANCEL_FILE_UPLOAD';
export const CANCEL_FILE_UPLOAD_SUCCESS = '[PACKAGE-FILES] CANCEL_FILE_UPLOAD_SUCCESS';
export const DOWNLOAD_FILE_PROGRESS = '[PACKAGE-FILES] DOWNLOAD_FILE_PROGRESS';
export const DOWNLOAD_FILE = '[PACKAGE-FILES] DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = '[PACKAGE-FILES] DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = '[PACKAGE-FILES] DOWNLOAD_FILE_ERROR';
export const CANCEL_DOWNLOAD_FILE = '[PACKAGE-FILES] CANCEL_DOWNLOAD_FILE';
export const CANCEL_DOWNLOAD_FILE_SUCCESS = '[PACKAGE-FILES] CANCEL_FILE_UPLOAD_SUCCESS';
export const LOAD_FILE_LOCATION = '[PACKAGE-FILES] LOAD_FILE_LOCATION';
export const LOAD_FILE_LOCATION_SUCCESS = '[PACKAGE-FILES] LOAD_FILE_LOCATION_SUCCESS';
export const DOWNLOADING_FILE_NAME_ACTION = '[PACKAGE-FILES] DOWNLOADING_FILE_NAME_ACTION';
export const START_DOWNLOADING_FILE_ACTION = '[PACKAGE-FILES] START_DOWNLOADING_FILE_ACTION';
export const DELETE_FILE = '[PACKAGE-FILES] DELETE_FILE';
export const DELETE_FILE_SUCCESS = '[PACKAGE-FILES] DELETE_FILE_SUCCESS';
export const DELETE_FILE_IN_REVISION = '[PACKAGE-FILES] DELETE_FILE_IN_REVISION';
export const DELETE_FILE_IN_REVISION_SUCCESS = '[PACKAGE-FILES] DELETE_FILE_IN_REVISION_SUCCESS';
export const REPLACE_FILE = '[PACKAGE-FILES] REPLACE_FILE';
export const REPLACE_FILE_SUCCESS = '[PACKAGE-FILES] REPLACE_FILE_SUCCESS';
export const REPLACE_FILE_ERROR = '[PACKAGE-FILES] REPLACE_FILE_ERROR';
export const REPLACE_FILE_PROGRESS = '[PACKAGE_FILES] REPLACE_FILE_PROGRESS';
export const CANCEL_FILE_REPLACE = '[PACKAGE_FILES] CANCEL_FILE_REPLACE';
export const CANCEL_FILE_REPLACE_SUCCESS = '[PACKAGE_FILES] CANCEL_FILE_REPLACE_SUCCESS';
export const REPLACE_FILE_IN_REVISION = '[PACKAGE-FILES] REPLACE_FILE_IN_REVISION';
export const REPLACE_FILE_IN_REVISION_SUCCESS = '[PACKAGE-FILES] REPLACE_FILE_IN_REVISION_SUCCESS';
export const REPLACE_FILE_IN_REVISION_ERROR = '[PACKAGE-FILES] REPLACE_FILE_IN_REVISION_ERROR';
export const REPLACE_FILE_IN_REVISION_PROGRESS = '[PACKAGE_FILES] REPLACE_FILE_IN_REVISION_PROGRESS';
export const CANCEL_FILE_IN_REVISION_REPLACE = '[PACKAGE_FILES] CANCEL_FILE_IN_REVISION_REPLACE';
export const CANCEL_FILE_IN_REVISION_REPLACE_SUCCESS = '[PACKAGE_FILES] CANCEL_FILE_IN_REVISION_REPLACE_SUCCESS';
export const GET_ASSIGNED_COUNTRIES = '[PACKAGE-FILES] GET_ASSIGNED_COUNTRIES';
export const GET_ASSIGNED_COUNTRIES_SUCCESS = '[PACKAGE-FILES] GET_ASSIGNED_COUNTRIES_SUCCESS';
export const CLEAN_UP_PACKAGE_FILES = '[PACKAGE-FILES] CLEAN_UP_PACKAGE_FILES';
export const CREATE_FILE_REVISION = '[PACKAGE-FILES] CREATE_FILE_REVISION';
export const CREATE_FILE_REVISION_SUCCESS = '[PACKAGE-FILES] CREATE_FILE_REVISION_SUCCESS';
export const REVERT_FILE_REVISION = '[PACKAGE-FILES] REVERT_FILE_REVISION';
export const REVERT_FILE_REVISION_SUCCESS = '[PACKAGE-FILES] REVERT_FILE_REVISION_SUCCESS';
export const PREVIEW_FILE_REVISION = '[PACKAGE-FILES] PREVIEW_FILE_REVISION';
export const PREVIEW_FILE_REVISION_SUCCESS = '[PACKAGE-FILES] PREVIEW_FILE_REVISION_SUCCESS';
export const CLEAN_UP_PREVIEW_FILE_REVISION = '[PACKAGE-FILES] CLEAN_UP_PREVIEW_FILE_REVISION';
// File Types actions
export const LoadFileTypesAction = createAction(LOAD_FILE_TYPES);

export const LoadFileTypesSuccessAction = createAction(LOAD_FILE_TYPES_SUCCESS, props<{ payload: Array<FileType> }>());

// File preview actions
export const GetFilePreviewAction =
  createAction(GET_FILE_PREVIEW, props<{ payload: { packageId: number; fileId: number }}>());

export const GetFilePreviewSuccessAction = createAction(GET_FILE_PREVIEW_SUCCESS, props<{ fileData: Blob }>());

export const FilePreviewCleanUpAction = createAction(FILE_PREVIEW_CLEANUP);

// Upload file (chunks) actions
export const UploadFileAction = createAction(UPLOAD_FILE, props<{ payload: ChunkUpload}>());

export const UploadFileSuccessAction = createAction(UPLOAD_FILE_SUCCESS, props<{ filesData: Array<PackageFile> }>());

export const UploadFileErrorAction = createAction(UPLOAD_FILE_ERROR, props<{ payload: UploadFileErrorResponse }>());

export const UploadFileProgressAction = createAction(UPLOAD_FILE_PROGRESS, props<{ payload: UploadProgress }>());

export const CancelFileUploadAction = createAction(CANCEL_FILE_UPLOAD);

export const CancelFileUploadSuccessAction = createAction(CANCEL_FILE_UPLOAD_SUCCESS);

// Download a file actions
export const DownloadingFileNameAction = createAction(DOWNLOADING_FILE_NAME_ACTION, props<{ payload: string }>());

export const StartDownloadingFileAction = createAction(DOWNLOADING_FILE_NAME_ACTION, props<{ payload: boolean }>());

export const DownloadFileAction =
  createAction(DOWNLOAD_FILE, props<{ payload: { packageId: number, fileIds: string } }>());

export const DownloadFileSuccessAction = createAction(DOWNLOAD_FILE_SUCCESS, props<{ payload: {
    fileLocationUrl: string
  }}>());

export const LoadFileLocationAction =
  createAction(LOAD_FILE_LOCATION, props<{ payload: { fileName: string, packageId: number } }>());

export const LoadFileLocationSuccessAction =
  createAction(LOAD_FILE_LOCATION_SUCCESS, props<{ payload: string }>());


export const DownloadFileErrorAction = createAction(DOWNLOAD_FILE_ERROR);

export const DownloadFileProgressAction = createAction(DOWNLOAD_FILE_PROGRESS, props<{ payload: DownloadProgress }>());

export const CancelDownloadFileAction = createAction(CANCEL_DOWNLOAD_FILE);

export const CancelDownloadFileSuccessAction = createAction(CANCEL_DOWNLOAD_FILE_SUCCESS);

// Delete file actions
export const DeleteFileAction = createAction(DELETE_FILE, props<{ payload: { packageId: number; fileId: number }}>());

export const DeleteFileSuccessAction = createAction(DELETE_FILE_SUCCESS);

// Delete file in revision actions
export const DeleteFileInRevisionAction = createAction(DELETE_FILE_IN_REVISION,
  props<{ payload: { packageId: number; fileId: number; revisionUuid: string }}>());

export const DeleteFileInRevisionSuccessAction = createAction(DELETE_FILE_IN_REVISION_SUCCESS,
  props<{ revisionFilesData: Array<PackageFileRevision> }>());

// Get the assigned countries actions
export const GetAssignedCountriesAction =
  createAction(GET_ASSIGNED_COUNTRIES, props<{ payload: { packageId: number } }>());

export const GetAssignedCountriesSuccessAction =
  createAction(GET_ASSIGNED_COUNTRIES_SUCCESS, props<{ countries: Array<Country> }>());

// Replace file (chunks) actions
export const ReplaceFileAction = createAction(REPLACE_FILE, props<{ payload: ChunkUpload}>());

export const ReplaceFileSuccessAction = createAction(REPLACE_FILE_SUCCESS, props<{ filesData: Array<PackageFile> }>());

export const ReplaceFileErrorAction = createAction(REPLACE_FILE_ERROR);

export const ReplaceFileProgressAction = createAction(REPLACE_FILE_PROGRESS, props<{ payload: UploadProgress }>());

export const CancelFileReplaceAction = createAction(CANCEL_FILE_REPLACE);

export const CancelFileReplaceSuccessAction = createAction(CANCEL_FILE_REPLACE_SUCCESS);

// Replace file in revision (chunks) actions
export const ReplaceFileInRevisionAction =
  createAction(REPLACE_FILE_IN_REVISION, props<{ payload: RevisionChunkUpload}>());

export const ReplaceFileInRevisionSuccessAction =
  createAction(REPLACE_FILE_IN_REVISION_SUCCESS, props<{ filesData: Array<PackageFile> }>());

export const ReplaceFileInRevisionErrorAction = createAction(REPLACE_FILE_IN_REVISION_ERROR);

export const ReplaceFileInRevisionProgressAction =
  createAction(REPLACE_FILE_IN_REVISION_PROGRESS, props<{ payload: UploadProgress }>());

export const CancelFileInRevisionReplaceAction = createAction(CANCEL_FILE_IN_REVISION_REPLACE);

export const CancelFileInRevisionReplaceSuccessAction = createAction(CANCEL_FILE_IN_REVISION_REPLACE_SUCCESS);

//clean up
export const CleanUpPackageFilesAction = createAction(CLEAN_UP_PACKAGE_FILES);

// Create file revision actions
export const CreateFileRevisionAction = createAction(CREATE_FILE_REVISION, props<{ payload: RevisionChunkUpload}>());

export const CreateFileRevisionSuccessAction = createAction(
  CREATE_FILE_REVISION_SUCCESS, props<{ revisionFilesData: Array<PackageFileRevision> }>()
);

// Revert file revision actions
export const RevertFileRevisionAction = createAction(
  REVERT_FILE_REVISION, props<{ payload: { packageId: number; fileRevisionId: number; revisionUuid: string }}>()
);

export const RevertFileRevisionSuccessAction = createAction(
  REVERT_FILE_REVISION_SUCCESS, props<{ revisionFilesData: Array<PackageFileRevision> }>()
);

// Preview file revision actions
export const PreviewFileRevisionAction = createAction(
  PREVIEW_FILE_REVISION, props<{ payload: { packageId: number; fileId: number;}}>()
);

export const PreviewFileRevisionSuccessAction = createAction(
  PREVIEW_FILE_REVISION_SUCCESS, props<{ fileData: Blob }>()
);

export const CleanUpPreviewFileRevision = createAction(CLEAN_UP_PREVIEW_FILE_REVISION);

