import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { authConfig } from './modules/auth/auth.config';
import { InstanaService } from './modules/shared/services/instana/instana.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  constructor(
        private readonly oauthService: OAuthService,
        private readonly router: Router,
        private readonly instanaService: InstanaService
  ) {
    this.configure();
  }

  ngAfterViewInit(): void {
    this.instanaService.loadInstanaMonitoringScript();
    this.instanaService.loadInstanaMonitoringRouterEvents();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private configure(): void{
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        this.router.initialNavigation();
        this.setupAutomaticLogoutInCaseOfTokenExpiry();
      }
    );
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry(): void {
    if (!this.oauthService.events) {
      return;
    }

    this.oauthService.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: OAuthEvent) => {
        if (event.type === 'token_expires') {
          this.router.navigateByUrl('login');
        }
      });
  }
}
