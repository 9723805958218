import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Country } from '../../interfaces/country/country.interface';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private readonly http: HttpClient) { }

  get(name: string): Observable<Array<Country>> {
    const params = new HttpParams().append('name', name);

    return this.http.get<Array<Country>>(`${environment.apiUrl}/countries`, { params });
  }

  /**
   * Retrieves countries for specific region.
   *
   * @param id - The id of the region.
   */
  getRegionCountries(id: number): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(`${environment.apiUrl}/regions/${id}/countries`);
  }
}
