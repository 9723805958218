import { Action, createReducer, on } from '@ngrx/store';

import { PagingResponseAuditPackageModel } from '../../modules/shared/models/paging/paging-response-audit-package.model';
import * as AuditExports from './audit-exports.actions';
import { AuditExportsState, initialState } from './audit-exports.state';

export const auditExportsFeatureKey = 'auditExports';

const auditExportsReducer = createReducer<AuditExportsState>(
  initialState,
  on(AuditExports.LoadAuditExportsPackagesAction, (state: AuditExportsState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(AuditExports.LoadAuditExportsPackagesSuccessAction, (state: AuditExportsState, { payload }) => {
    return {
      ...state,
      packages: payload,
      loading: false
    };
  }),
  on(AuditExports.ScrollAuditExportsPackagesAction, (state: AuditExportsState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(AuditExports.ScrollAuditExportsPackagesSuccessAction, (state: AuditExportsState, { payload }) => {
    const packages = {...payload};
    packages.content = state.packages.content.concat(payload.content);

    return {
      ...state,
      packages,
      loading: false
    };
  }),
  on(AuditExports.LoadAuditExportPdfDataSuccessAction, (state: AuditExportsState, {payload}) => ({
    ...state,
    pdfExport: payload
  })),
  on(AuditExports.CleanupAuditExportsPackagesAction, (state: AuditExportsState) => {
    return {
      packages: new PagingResponseAuditPackageModel(),
      pdfExport: null,
      loading: true
    };
  })
);

export function AuditExportsReducer(state: AuditExportsState | undefined, action: Action): AuditExportsState {
  return auditExportsReducer(state, action);
}
