import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Article } from '../../interfaces/article/article';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  constructor(private readonly http: HttpClient) { }

  get(id: string, family: string, type: string): Observable<Array<Article>> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });

    if (id) {
      params = params.append('id', id);
    }

    if (family) {
      params = params.append('family', family);
    }

    if (type) {
      params = params.append('type', type);
    }

    return this.http.get<Array<Article>>(`${environment.apiUrl}/articles`, { params });
  }
}
