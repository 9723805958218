import { Action, createReducer, on } from '@ngrx/store';

import { PackageDetailsModel } from '../../modules/shared/models/packages/package-details.model';
import { GetRegionsSuccessAction } from './package-assignments/package-assignments.actions';
import * as PackageDetailsActions from './package-details.actions';
import { initialState, PackageDetailsState } from './package-details.state';

export const packageDetailsFeatureKey = 'packageDetails';

const packageDetailsReducer = createReducer<PackageDetailsState>(
  initialState,
  on(PackageDetailsActions.LoadActionIdAction, (state: PackageDetailsState, {actionId}) => {
    return {
      ...state,
      actionId
    };
  }),
  on(PackageDetailsActions.LoadPackageDetailsSuccessAction, (state: PackageDetailsState, {payload}) => {
    return {
      ...state,
      details: payload
    };
  }),
  on(PackageDetailsActions.LoadPackageRevisionSuccessAction, (state: PackageDetailsState, {payload}) => {
    return {
      ...state,
      details: payload
    };
  }),
  on(PackageDetailsActions.LoadAllUsersSuccessAction, (state: PackageDetailsState, {payload}) => {
    return {
      ...state,
      allUsers: payload
    };
  }),
  on(PackageDetailsActions.GetAssignedUserRolesSuccessAction, (state: PackageDetailsState, {payload}) => {
    return {
      ...state,
      assignedUserRoles: payload
    };
  }),
  on(PackageDetailsActions.UpdateDetailsCountries, (state: PackageDetailsState, {payload}) => {
    const details = {
      ...state.details,
      countries: payload
    };
    return {
      ...state,
      details
    };
  }),
  on(GetRegionsSuccessAction, (state: PackageDetailsState, {regions}) => {
    const details = {
      ...state.details,
      regions: regions
    };

    return {
      ...state,
      details
    };
  }),
  on(PackageDetailsActions.CleanupPackageDetailsAction, () => {
    return {
      actionId: null,
      details: new PackageDetailsModel(),
      assignedUserRoles: [],
      allUsers: [],
      isFormDisabled: false
    };
  })
);

export function PackageDetailsReducer(state: PackageDetailsState | undefined, action: Action): PackageDetailsState {
  return packageDetailsReducer(state, action);
}

