import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TgNotificationService } from '@bbraun/toolguide';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NOTIFICATION_DURATION_IN_MS } from '../../modules/shared/constants/notification/notification.constants';
import { Article } from '../../modules/shared/interfaces/article/article';
import { NewPackage, Package } from '../../modules/shared/interfaces/package/package.interface';
import { Product } from '../../modules/shared/interfaces/product/product.interface';
import { ProductFamily } from '../../modules/shared/interfaces/product-family/product-family.interface';
import { ArticlesService } from '../../modules/shared/services/articles/articles.service';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { ProductFamilyService } from '../../modules/shared/services/product-family/product-family.service';
import { ProductsService } from '../../modules/shared/services/products/products.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as NewPackageActions from './new-package.actions';

@Injectable()
export class NewPackageEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly productFamilyService: ProductFamilyService,
        private readonly productsService: ProductsService,
        private readonly packagesService: PackagesService,
        private readonly articlesService: ArticlesService,
        private readonly notification: TgNotificationService,
        private readonly router: Router
  ) {
  }

  loadProductFamily$ = createEffect(() => this.actions$.pipe(
    ofType(NewPackageActions.LoadProductFamilyAction),
    map((action) => action.term),
    switchMap((term: string) =>
      this.productFamilyService.get(term).pipe(
        map((productFamily: Array<ProductFamily>) => {
          return NewPackageActions.LoadProductFamilySuccessAction({payload: productFamily});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  loadProducts$ = createEffect(() => this.actions$.pipe(
    ofType(NewPackageActions.LoadProductsAction),
    switchMap((action) =>
      this.productsService.get(action.term, action.productFamily).pipe(
        map((products: Array<Product>) => {
          return NewPackageActions.LoadProductsSuccessAction({payload: products});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  loadArticles$ = createEffect(() => this.actions$.pipe(
    ofType(NewPackageActions.LoadArticlesAction),
    switchMap((action) =>
      this.articlesService.get(action.id, action.productFamily, action.productType).pipe(
        map((articles: Array<Article>) => {
          return NewPackageActions.LoadArticlesSuccessAction({payload: articles});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  createPackage$ = createEffect(() => this.actions$.pipe(
    ofType(NewPackageActions.CreatePackageAction),
    map((action) => action.payload),
    switchMap((payload: NewPackage) => {
      return this.packagesService.createPackage(payload).pipe(
        map((pkg: Package) => {
          this.notification.openSnackBar('The package was successfully created.', NOTIFICATION_DURATION_IN_MS);
          this.router.navigate(['package-details', pkg.id], {state: {isNavigatedFromNewPackage: true}});
          return NewPackageActions.CreatePackageSuccessAction();
        }),
        catchError((error: HttpErrorResponse) => {
          return of(new GenericErrorAction(error));
        })
      );
    }
    )
  ));
}
