import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HTTP_STATUS_CODES } from '@bbraun/toolguide/shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (ev: HttpEvent<any>) => {
          return ev;
        },
        (err: any) => {
          if (req.url.includes('fst-import-preview') && err.status === HTTP_STATUS_CODES.BAD_REQUEST) {
            return;
          }

          if (req.url.endsWith('files') && req.method === 'POST') {
            return;
          }

          if (err instanceof HttpErrorResponse) {
            if (err.status === HTTP_STATUS_CODES.CONFLICT) {
              return;
            } else {
              this.router.navigate(['error'], {
                queryParams: {
                  errorStatusCode: err.status,
                  errorCodeIoMT: err?.error?.errorCode
                }
              });
            }
          }
        }
      )
    );
  }
}
