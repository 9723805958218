import { Country } from '../../interfaces/country/country.interface';
import { LdapUser } from '../../interfaces/ldap-user/ldap-user.interface';
import { ArchivedReasonAndDate } from '../../interfaces/package/archived-reason-and-date.interface';
import { Package } from '../../interfaces/package/package.interface';
import { PackageFile } from '../../interfaces/package-file/package-file.interface';
import { RevisionChanges } from '../../interfaces/revision-changes/revision-changes.interface';

export class PackageDetailsModel {
  packageInfo: Package;
  countries: Array<any>;
  approver: LdapUser;
  grc: LdapUser;
  contentManager: LdapUser;
  files: Array<PackageFile>;
  unassignedCountries: Array<Country>;
  archive: ArchivedReasonAndDate;
  changes: RevisionChanges;

  constructor(
    packageInfo?: Package,
    countries?: Array<any>,
    approver?: LdapUser,
    grc?: LdapUser,
    contentManager?: LdapUser,
    files?: Array<PackageFile>,
    changes?: RevisionChanges,
    unassignedCountries?: Array<Country>,
    archive?: ArchivedReasonAndDate
  ) {
    this.packageInfo = packageInfo ? packageInfo : {
      id: null,
      productType: null,
      name: null,
      softwareVersion: null,
      productFamily: null,
      softwareReleaseDate: null,
      description: null,
      action: null,
      status: null,
      articles: [],
      declineReason: null,
      prid: null,
      components: [],
      hardwareRevision: null,
      underRevision: null
    };
    this.countries = countries ? countries : [];
    this.approver = approver ? approver : null;
    this.grc = grc ? grc : null;
    this.contentManager = contentManager ? contentManager : null;
    this.files = files ? files : [];
    this.changes = null;
    this.unassignedCountries = unassignedCountries ? unassignedCountries : [];
    this.archive = archive ? archive : null;
  }
}
