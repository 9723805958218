import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Save value to either sessionStorage or localStorage (if specified)
   * @param key
   * @param value
   * @param storageType Type of storage to set
   */
  setItem(key: string, value: any, storageType: Storage = sessionStorage): void {
    storageType.setItem(key, this.encode(value));
  }

  /**
   * Get item from either sessionStorage or localStorage (if specified)
   * @param key Name of item to retrieve from storage
   * @param storageType Type of storage to look into
   */
  getItem(key: string, storageType: Storage = sessionStorage): any {
    const value = storageType.getItem(key);
    return value ? this.decode(value) : null;
  }

  /**
   * Checks whether an entry with the specified key exists in the storage.
   * @param key Name to check for
   * @param storageType Check for a specific storage
   */
  hasItem(key: string, storageType: Storage = sessionStorage): boolean {
    return !!storageType.getItem(key);
  }

  /**
   * Remove an item from storage
   * @param key
   * @param storageType
   */
  removeItem(key: string, storageType: Storage = sessionStorage): void {
    storageType.removeItem(key);
  }

  /**
   * Clears the storage by removing all entries.
   * @param storageType
   */
  clear(storageType: Storage = sessionStorage): void {
    storageType.clear();
  }

  /**
   * Encode value into a string
   * @param value
   * @private
   */
  encode(value: any): string {
    return typeof value === 'string' ? value : JSON.stringify(value);
  }

  /**
   * Decode value from a stringified value
   * @param value
   * @private
   */
  decode(value: string): any {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }
}
