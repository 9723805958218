import { createAction, props } from '@ngrx/store';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';

export const GET_USER_INFO = '[INBOX] GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = '[INBOX] GET_USER_INFO_SUCCESS';
export const IS_USER_INFO_AVAILABLE = '[INBOX] HAS_USER_INFO';
export const CLEAN_UP_USER_INFO = '[INBOX] CLEAN_USER_INFO';

export const GetUserInfoAction = createAction(GET_USER_INFO);

export const GetUserInfoSuccessAction = createAction(GET_USER_INFO_SUCCESS, props<{ payload: UserInfo }>());

export const IsUserInfoAvailable = createAction(IS_USER_INFO_AVAILABLE);

export const CleanupUserInfoAction = createAction(CLEAN_UP_USER_INFO);
