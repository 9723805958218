import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';
import { AuthService } from '../../modules/shared/services/auth/auth.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService
  ) { }

  getUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.GET_USER_INFO),
    switchMap(() =>
      this.authService.getUserInfo().pipe(
        map((response: UserInfo) => {
          return AuthActions.GetUserInfoSuccessAction({ payload: response });
        }),
        catchError((error: Error) => {
          return of(AuthActions.CleanupUserInfoAction());
        })
      )
    )
  ));
}
