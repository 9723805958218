import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { environment } from "../../../../../environments/environment";
import { INBOX_TABLE } from "../../constants/table/table.constants";
import { PackageDetailsResponse } from '../../interfaces/package/package-details-response.interface';
import {
  PackageRevision,
  PackageRevisionDeclineRequest,
  PackageRevisionPostResponse,
  PackageRevisionPutResponse
} from '../../interfaces/package/package-revision.interface';
import { PagingResponse } from "../../interfaces/paging/paging-response.interface";
import { TableDataObject } from "../../interfaces/table-data-object/table-data-object.interface";
import { CustomHttpParamEncoderService } from "../custom-http-param-encoder/custom-http-param-encoder.service";
import { HttpBaseService } from '../http/http.base.service';

@Injectable({
  providedIn: 'root'
})
export class RevisionsService extends HttpBaseService {

  constructor(private readonly http: HttpClient) {
    super();
  }

  getRevisions(page: number, sort: string, searchParameter: string): Observable<PagingResponse<TableDataObject>> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('size', INBOX_TABLE.size.toString())
      .append('page', page.toString())
      .append('sort', sort.toString())
      .append('searchParameter', searchParameter);

    return this.http.get<PagingResponse<TableDataObject>>(`${environment.apiUrl}/revisions`, {params});
  }


  /**
   * Approves a revision for the provided package.
   *
   * @param revisionId - the id of the revision to be approved.
   * @param actionId   - the id of the action with which the package revision was opened.
   */
  approveRevision(revisionId: number, actionId: number): Observable<PackageRevisionPutResponse> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('actionId', `${actionId}`);

    return this.http.put<PackageRevisionPutResponse>(`${environment.apiUrl}/revisions/${revisionId}/approve`,
      params);
  }

  /**
   * Reworks an existing revision.
   *
   * @param revisionId    - the id of the revision to be reworked.
   * @param requestBody   - contains the updated countries, approver or grc.
   */
  reworkRevision(
    revisionId: number,
    requestBody: PackageRevision
  ): Observable<PackageRevisionPostResponse> {

    return this.http.put<PackageRevisionPostResponse>(
      `${environment.apiUrl}/revisions/${revisionId}/rework`, requestBody
    );
  }

  /**
   * Declines an existing revision.
   *
   * @param revisionId  - The id of the revision to be declined.
   * @param actionId    - The id of the action with which the package revision was opened.
   * @param requestBody - Contains the reason for the decline.
   */
  declineRevision(
    revisionId: number,
    actionId: number,
    requestBody: PackageRevisionDeclineRequest
  ): Observable<PackageRevisionPutResponse> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('actionId', `${actionId}`);

    return this.http.put<PackageRevisionPutResponse>(
      `${environment.apiUrl}/revisions/${revisionId}/decline`, requestBody, {params}
    );
  }

  /**
   * Retrieves the revision with all the data.
   *
   * @param actionId   - The id of the action with which the package revision was opened.
   * @param revisionId - The id of the revision to be declined.
   */
  getPackageRevision(actionId: number, revisionId: number): Observable<PackageDetailsResponse> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('actionId', actionId.toString());
    return this.http.get<PackageDetailsResponse>(`${environment.apiUrl}/revisions/${revisionId}`, {params});
  }

  /**
   * Delete a package revision.
   *
   * @param revisionId - The id of the revision to be declined.
   */
  deletePackageRevision(revisionId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/revisions/${revisionId}`);
  }

  /**
   * Creates a new revision for the provided package.
   *
   * @param packageId    - The id of the package to be archived.
   * @param revisionUuid - users session identifier used for linking the previous actions to the revision.
   * @param requestBody  - contains the updated countries, approver or grc.
   */
  revisionPackage(packageId: number, revisionUuid: string, requestBody: PackageRevision):
    Observable<PackageRevisionPostResponse> {
    const headers = new HttpHeaders({
      'revision-uuid': revisionUuid,
      'Content-Type': 'application/json'
    });

    return this.http.post<PackageRevisionPostResponse>(
      `${environment.apiUrl}/packages/${packageId}/revision`, requestBody, {headers}
    );
  }
}
