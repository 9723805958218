export const GENERIC_ERROR = '[Application] GENERIC ERROR';

/**
 * Generic action to handle errors. An effect listening to it will open a snack bar to inform the user. Use a more specific implementation
 * if a rollback of the state is required.
 *
 * The GenericErrorAction might carry a "cleanup" action as a special payload. If present, the effect listening for the GenericErrorAction
 * will dispatch this after displaying the notification message. The "cleanup" action can be used to reset some properties of the state back
 * to the desired value.
 *
 * Subclasses of this must not define its own "type" property and "reduce" method. Instead they should provide the appropriate "cleanup"
 * action by default. See SearchResultGenericErrorAction for an implementation of this pattern.
 */
export class GenericErrorAction {
  readonly type = GENERIC_ERROR;

  /**
   * Constructor.
   *
   * @param {error: Error} error error from backend
   * @param {Actions} cleanup optional "cleanup" action instance
   */
  constructor(
    readonly error: Error,
    readonly cleanup?: any // type is any due to bug with ngrx v8 Action type
  ) { }
}

export type Action = GenericErrorAction;
