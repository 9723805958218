import { RegionAssignments } from '../../../modules/shared/interfaces/assignment/region-assignments.interface';
import { Country } from '../../../modules/shared/interfaces/country/country.interface';
import {
  CustomerAutomaticDistribution
} from '../../../modules/shared/interfaces/customer/customer-automatic-distribution';
import { PagingResponse } from '../../../modules/shared/interfaces/paging/paging-response.interface';
import { GetRegionsHttpResponse } from '../../../modules/shared/interfaces/region/get-regions-http-response.interface';
import {
  RegionAssignmentsUpdateData,
  StoredRegionAssignments
} from '../../../modules/shared/interfaces/region/region-assignents-update.interface';
import {
  PagingResponseCustomersAutomaticDistributionModel
} from '../../../modules/shared/models/paging/paging-response-customers-automatic-distribution.model';

export interface PackageAssignmentsState {
  regions: Array<GetRegionsHttpResponse>;
  unassignedCountries: Array<Country>;
  regionAssignments: Array<RegionAssignments>;
  customers: PagingResponse<CustomerAutomaticDistribution>;
  userAssignmentsForm: StoredRegionAssignments;
  userAssignmentsChanges: Array<RegionAssignmentsUpdateData>;
}

export const initialState: PackageAssignmentsState = {
  regions: [],
  unassignedCountries: null,
  regionAssignments: [],
  customers: new PagingResponseCustomersAutomaticDistributionModel(),
  userAssignmentsForm: null,
  userAssignmentsChanges: []
};
