import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageHttpRequestService {
  private readonly pendingHTTPRequests$ = new Subject<void>();

  cancelPendingRequests(): void {
    this.pendingHTTPRequests$.next();
  }

  onCancelPendingRequests(): Observable<void> {
    return this.pendingHTTPRequests$.asObservable();
  }
}
