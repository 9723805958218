import { Action, createReducer, on } from '@ngrx/store';

import * as InboxActions from './new-package.actions';
import { initialState, NewPackageState } from './new-package.state';

export const newPackageFeatureKey = 'newPackage';

const newPackageReducer = createReducer<NewPackageState>(
  initialState,
  on(InboxActions.LoadProductFamilySuccessAction, (state: NewPackageState, { payload }) => {
    return {
      ...state,
      productFamily: payload
    };
  }),
  on(InboxActions.LoadProductsSuccessAction, (state: NewPackageState, { payload }) => {
    return {
      ...state,
      products: payload
    };
  }),
  on(InboxActions.LoadArticlesSuccessAction, (state: NewPackageState, { payload }) => {
    return {
      ...state,
      articles: payload
    };
  }),
  on(InboxActions.CleanUpNewPackageAction, () => {
    return {
      productFamily: [],
      products: [],
      articles: []
    };
  })
);

export function NewPackageReducer(state: NewPackageState | undefined, action: Action): NewPackageState {
  return newPackageReducer(state, action);
}
