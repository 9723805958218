import { createAction, props } from '@ngrx/store';
import { TableDataObject } from 'src/app/modules/shared/interfaces/table-data-object/table-data-object.interface';

import { PackageHttpRequest } from '../../modules/shared/interfaces/package/package-http-request.interface';
import { PendingPackagesCount } from "../../modules/shared/interfaces/package/pending-packages-count.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';

export const LOAD_PACKAGES = '[INBOX] LOAD_PACKAGES';
export const LOAD_PACKAGES_SUCCESS = '[INBOX] LOAD_PACKAGES_SUCCESS';
export const SCROLL_PACKAGES = '[INBOX] SCROLL_PACKAGES';
export const SCROLL_PACKAGES_SUCCESS = '[INBOX] SCROLL_PACKAGES_SUCCESS';
export const GET_PENDING_PACKAGES_COUNT = '[INBOX] GET_PENDING_PACKAGES_COUNT';
export const GET_PENDING_PACKAGES_COUNT_SUCCESS = '[INBOX] GET_PENDING_PACKAGES_COUNT_SUCCESS';
export const CLEANUP_PACKAGES = '[INBOX] CLEAN_PACKAGES';

export const LoadPackagesAction = createAction(LOAD_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const LoadPackagesSuccessAction =
  createAction(LOAD_PACKAGES_SUCCESS, props<{ payload: PagingResponse<TableDataObject> }>());

export const ScrollPackagesAction = createAction(SCROLL_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const ScrollPackagesSuccessAction =
  createAction(SCROLL_PACKAGES_SUCCESS, props<{ payload: PagingResponse<TableDataObject> }>());

export const GetPendingPackagesCountAction = createAction(GET_PENDING_PACKAGES_COUNT);

export const GetPendingPackagesCountSuccessAction =
  createAction(GET_PENDING_PACKAGES_COUNT_SUCCESS, props<{ payload: PendingPackagesCount }>());

export const CleanupPackagesAction = createAction(CLEANUP_PACKAGES);
