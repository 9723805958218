import { createAction, props } from '@ngrx/store';

import { AuditExportData } from '../../modules/shared/interfaces/audit-exports/audit-export-data.interface';
import { AuditExportsTablePackage } from '../../modules/shared/interfaces/audit-exports/audit-export-table-package.interface';
import { PackageHttpRequest } from '../../modules/shared/interfaces/package/package-http-request.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';

export const LOAD_AUDIT_EXPORTS_PACKAGES = '[AUDIT_EXPORT] LOAD_AUDIT_EXPORT_PACKAGES';
export const LOAD_AUDIT_EXPORTS_PACKAGES_SUCCESS = '[AUDIT_EXPORT] LOAD_AUDIT_EXPORT_PACKAGES_SUCCESS';
export const SCROLL_AUDIT_EXPORTS_PACKAGES = '[AUDIT_EXPORT] SCROLL_AUDIT_EXPORT_PACKAGES';
export const SCROLL_AUDIT_EXPORTS_PACKAGES_SUCCESS = '[AUDIT_EXPORT] SCROLL_AUDIT_EXPORT_PACKAGES_SUCCESS';
export const LOAD_AUDIT_EXPORTS_PDF_DATA = '[AUDIT_EXPORT] LOAD_AUDIT_EXPORTS_PDF_DATA';
export const LOAD_AUDIT_EXPORTS_PDF_DATA_SUCCESS = '[AUDIT_EXPORT] LOAD_AUDIT_EXPORTS_PDF_DATA_SUCCESS';
export const CLEANUP_AUDIT_EXPORTS_PACKAGES = '[AUDIT_EXPORT] CLEAN_AUDIT_EXPORT_PACKAGES';

export const LoadAuditExportsPackagesAction =
  createAction(LOAD_AUDIT_EXPORTS_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const LoadAuditExportsPackagesSuccessAction =
  createAction(LOAD_AUDIT_EXPORTS_PACKAGES_SUCCESS, props<{ payload: PagingResponse<AuditExportsTablePackage> }>());

export const ScrollAuditExportsPackagesAction =
  createAction(SCROLL_AUDIT_EXPORTS_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const ScrollAuditExportsPackagesSuccessAction =
  createAction(SCROLL_AUDIT_EXPORTS_PACKAGES_SUCCESS, props<{ payload: PagingResponse<AuditExportsTablePackage> }>());

export const LoadAuditExportPdfDataAction =
  createAction(LOAD_AUDIT_EXPORTS_PDF_DATA, props<{ payload: {packageId: number} }>());

export const LoadAuditExportPdfDataSuccessAction =
  createAction(LOAD_AUDIT_EXPORTS_PDF_DATA_SUCCESS, props<{ payload: Array<AuditExportData> }>());

export const CleanupAuditExportsPackagesAction = createAction(CLEANUP_AUDIT_EXPORTS_PACKAGES);
