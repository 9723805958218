import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authFeatureKey } from './auth.reducer';
import { AuthState } from './auth.state';

export const getFeatureState = createFeatureSelector<AuthState>(authFeatureKey);

export const getUserInfo = createSelector(
  getFeatureState,
  (state) => state.userInfo
);

export const isUserInfoAvailable = createSelector(
  getFeatureState,
  (state) => !!state.userInfo
);
