import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HTTP_STATUS_CODES } from "@bbraun/toolguide/shared";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { interval, of, Subject } from 'rxjs';
import { catchError, delay, filter, map, retry, switchMap, takeUntil, takeWhile, throttle } from 'rxjs/operators';

import {
  DELAY_LOAD_FILE_LOCATION,
  UPLOAD_CHUNK_RETRIES
} from '../../../modules/shared/constants/package-file/package-files.constants';
import { Country } from '../../../modules/shared/interfaces/country/country.interface';
import { FileType } from '../../../modules/shared/interfaces/file-type/file-type.interface';
import {
  ChunkUpload,
  RevisionChunkUpload
} from '../../../modules/shared/interfaces/package-file/chunk-upload.interface';
import { FileService } from '../../../modules/shared/services/file/file.service';
import { PackagesService } from '../../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../../shared/shared.actions';
import * as PackageFilesActions from './package-files.actions';

@Injectable()
export class PackageFilesEffects {

  private readonly destroy$ = new Subject<void>();

  private isPollingActive = true;

  constructor(
    private readonly actions$: Actions,
    private readonly fileService: FileService,
    private readonly packagesService: PackagesService
  ) {
  }

  loadFileTypes$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.LoadFileTypesAction),
    switchMap(() =>
      this.fileService.get().pipe(
        map((fileType: Array<FileType>) => {
          return PackageFilesActions.LoadFileTypesSuccessAction({payload: fileType});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getAssignedCountries$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.GetAssignedCountriesAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.getAssignedCountries(action.packageId).pipe(
        map((countries: Array<Country>) => {
          return PackageFilesActions.GetAssignedCountriesSuccessAction({countries});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getFilePreview$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.GetFilePreviewAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.fileService.previewFile(payload.packageId, payload.fileId).pipe(
        map((fileData: Blob) => {
          return PackageFilesActions.GetFilePreviewSuccessAction({fileData: fileData});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  deleteFile$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.DeleteFileAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.fileService.deleteFile(payload.packageId, payload.fileId).pipe(
        map(() => PackageFilesActions.DeleteFileSuccessAction()),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  uploadFile$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.UploadFileAction),
    map((action) => action.payload),
    throttle(() => interval(0)),
    switchMap((payload) => {
      return this.fileService.uploadPackageFile(
        payload.packageId,
        payload.fileChunks[payload.chunkIndex],
        payload.chunkIndex,
        payload.totalChunks)
        .pipe(
          takeUntil(this.destroy$),
          retry(UPLOAD_CHUNK_RETRIES),
          filter((response) => response.type === HttpEventType.Response ||
            response.type === HttpEventType.UploadProgress),
          map((response) => {
            return this.handleUploadFile(payload, response);
          }),
          catchError((err) => {
            const errResponse = {
              status: err.status,
              url: err.url,
              message: err.message
            };
            return of(PackageFilesActions.UploadFileErrorAction({payload: errResponse}));
          })
        );
    })
  ));

  cancelFileUpload$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.CancelFileUploadAction),
    switchMap(() => {
      this.destroy$.next();
      this.destroy$.complete();

      return [PackageFilesActions.CancelFileUploadSuccessAction()];
    })
  ));

  replaceFile$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.ReplaceFileAction),
    map((action) => action.payload),
    throttle(() => interval(0)),
    switchMap((payload) => {
      return this.fileService.replacePackageFile(
        payload.packageId,
        payload.fileChunks[payload.chunkIndex],
        payload.chunkIndex,
        payload.totalChunks)
        .pipe(
          takeUntil(this.destroy$),
          retry(UPLOAD_CHUNK_RETRIES),
          filter((response) =>
            response.type === HttpEventType.Response || response.type === HttpEventType.UploadProgress),
          map((response) => {
            return this.handleReplaceFile(payload, response);
          }),
          catchError(() => {
            return of(PackageFilesActions.ReplaceFileErrorAction());
          })
        );
    })
  ));

  cancelFileReplace$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.CancelFileReplaceAction),
    switchMap(() => {
      this.destroy$.next();
      this.destroy$.complete();

      return [PackageFilesActions.CancelFileReplaceSuccessAction()];
    })
  ));

  downloadFile$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.DownloadFileAction),
    map((action) => action.payload),
    map((payload) => {
      this.isPollingActive = true;

      return payload;
    }),
    switchMap((payload) => {
      return this.fileService.downloadFiles(payload.packageId, payload.fileIds)
        .pipe(
          filter((response) => response.type === HttpEventType.Response || response.type === HttpEventType.Sent),
          map((response) => {
            return PackageFilesEffects.handleDownloadFile(payload, response);
          }),
          catchError((err) => {
            return of(PackageFilesActions.DownloadFileErrorAction());
          })
        );
    })
  ));

  loadFileLocation$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.LoadFileLocationAction),
    throttle(() => interval(0)),
    map((action) => action.payload),
    delay(DELAY_LOAD_FILE_LOCATION),
    switchMap((payload) => {
      return this.fileService.getFileLocation(
        payload.fileName,
        payload.packageId)
        .pipe(
          takeWhile(() => this.isPollingActive),
          filter((response) => response.type === HttpEventType.Response),
          map((payload) => {
            this.isPollingActive = true;

            return payload;
          }),
          map((response) => {
            if ( response.status === HTTP_STATUS_CODES.CREATED) {
              this.isPollingActive = false;
            }

            return PackageFilesEffects.getFileLocation(response, payload);
          }),
          catchError(() => {
            return of(PackageFilesActions.DownloadFileErrorAction());
          })
        );
    })
  ));

  cancelDownloadFile$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.CancelDownloadFileAction),
    map(() => this.isPollingActive = false),
    switchMap(() => of(null))), { dispatch: false }
  );

  replaceFileInRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.ReplaceFileInRevisionAction),
    map((action) => action.payload),
    throttle(() => interval(0)),
    switchMap((payload) => {
      return this.fileService.replaceFileInRevision(
        payload.packageId,
        payload.fileChunks[payload.chunkIndex],
        payload.chunkIndex,
        payload.totalChunks,
        payload.fileId,
        payload.revisionUuid)
        .pipe(
          takeUntil(this.destroy$),
          retry(UPLOAD_CHUNK_RETRIES),
          map((response) => {
            return this.handleReplaceFileInRevision(payload, response);
          }),
          catchError(() => {
            return of(PackageFilesActions.ReplaceFileInRevisionErrorAction());
          })
        );
    })
  ));

  deleteFileInRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.DeleteFileInRevisionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.fileService.deleteFileInRevision(payload.packageId, payload.fileId, payload.revisionUuid).pipe(
        map((response) => PackageFilesActions.DeleteFileInRevisionSuccessAction({revisionFilesData: response})),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  createFileRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.CreateFileRevisionAction),
    map((action) => action.payload),
    throttle(() => interval(0)),
    switchMap((payload) => {
      return this.fileService.createFileRevision(
        payload.packageId,
        payload.revisionUuid,
        payload.fileChunks[payload.chunkIndex],
        payload.chunkIndex,
        payload.totalChunks
      ).pipe(
        takeUntil(this.destroy$),
        retry(UPLOAD_CHUNK_RETRIES),
        filter((response) => response.type === HttpEventType.Response ||
          response.type === HttpEventType.UploadProgress),
        map((response) => this.handleCreateFileRevision(payload, response)),
        catchError((err) => {
          const errResponse = {
            status: err.status,
            url: err.url,
            message: err.message
          };

          return of(PackageFilesActions.UploadFileErrorAction({payload: errResponse}));
        })
      );
    })
  ));

  revertFileRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.RevertFileRevisionAction),
    map((action) => action.payload),
    switchMap((payload) => {
      return this.fileService.revertFileRevision(
        payload.packageId,
        payload.fileRevisionId,
        payload.revisionUuid
      ).pipe(
        map((response) => PackageFilesActions.RevertFileRevisionSuccessAction({revisionFilesData: response})),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      );
    })
  ));

  previewFileRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageFilesActions.PreviewFileRevisionAction),
    map((action) => action.payload),
    switchMap((payload) => {
      return this.fileService.previewFileRevision(payload.packageId, payload.fileId)
        .pipe(
          map((response) => PackageFilesActions.PreviewFileRevisionSuccessAction({fileData: response})),
          catchError((error: Error) => of(new GenericErrorAction(error)))
        );
    })
  ));

  private handleReplaceFile(payload: ChunkUpload, response): Action {
    if (response.type === HttpEventType.Response) {
      if (payload.chunkIndex + 1 === payload.totalChunks) {
        return PackageFilesActions.ReplaceFileSuccessAction({filesData: response.body});
      }

      return PackageFilesActions.ReplaceFileAction({
        payload: {
          packageId: payload.packageId,
          fileChunks: payload.fileChunks,
          chunkIndex: payload.chunkIndex + 1,
          totalChunks: payload.totalChunks
        }
      });
    }


    // in case the response type is  HttpEventType.UploadProgress
    return PackageFilesActions.ReplaceFileProgressAction({
      payload: {
        loaded: response.loaded,
        total: response.total,
        chunkNumber: payload.chunkIndex + 1,
        totalChunks: payload.totalChunks
      }
    });
  }

  private handleReplaceFileInRevision(payload: RevisionChunkUpload, response): Action {
    if (response.type === HttpEventType.Response) {
      if (payload.chunkIndex + 1 === payload.totalChunks) {
        return PackageFilesActions.ReplaceFileInRevisionSuccessAction({filesData: response.body});
      }

      return PackageFilesActions.ReplaceFileInRevisionAction({
        payload: {
          packageId: payload.packageId,
          fileChunks: payload.fileChunks,
          chunkIndex: payload.chunkIndex + 1,
          totalChunks: payload.totalChunks,
          revisionUuid: payload.revisionUuid
        }
      });
    }

    if (Array.isArray(response)) {
      return PackageFilesActions.ReplaceFileInRevisionSuccessAction({filesData: response});
    }

    // in case the response type is  HttpEventType.UploadProgress
    return PackageFilesActions.UploadFileProgressAction({
      payload: {
        loaded: response.loaded,
        total: response.total,
        chunkNumber: payload.chunkIndex + 1,
        totalChunks: payload.totalChunks
      }
    });
  }

  private handleUploadFile(payload: ChunkUpload, response): Action {
    if (response.type === HttpEventType.Response) {
      if (payload.chunkIndex + 1 === payload.totalChunks) {
        return PackageFilesActions.UploadFileSuccessAction({filesData: response.body});
      }

      return PackageFilesActions.UploadFileAction({
        payload: {
          packageId: payload.packageId,
          fileChunks: payload.fileChunks,
          chunkIndex: payload.chunkIndex + 1,
          totalChunks: payload.totalChunks
        }
      });
    }

    return PackageFilesActions.UploadFileProgressAction({
      payload: {
        loaded: response.loaded,
        total: response.total,
        chunkNumber: payload.chunkIndex + 1,
        totalChunks: payload.totalChunks
      }
    });
  }

  private handleCreateFileRevision(payload: RevisionChunkUpload, response): Action {
    if (response.type === HttpEventType.Response) {
      if (payload.chunkIndex + 1 === payload.totalChunks) {
        return PackageFilesActions.CreateFileRevisionSuccessAction({revisionFilesData: response.body});
      }

      return PackageFilesActions.CreateFileRevisionAction({
        payload: {
          packageId: payload.packageId,
          revisionUuid: payload.revisionUuid,
          fileChunks: payload.fileChunks,
          chunkIndex: payload.chunkIndex + 1,
          totalChunks: payload.totalChunks
        }
      });
    }

    return PackageFilesActions.UploadFileProgressAction({
      payload: {
        loaded: response.loaded,
        total: response.total,
        chunkNumber: payload.chunkIndex + 1,
        totalChunks: payload.totalChunks
      }
    });
  }

  private static handleDownloadFile(payload: any, response: any): Action {
    const fileName = response?.headers?.get('Content-Location');

    if (response.type === HttpEventType.Response) {
      return PackageFilesActions.LoadFileLocationAction({
        payload: {
          packageId: payload.packageId, fileName
        }
      });
    }

    return PackageFilesActions.DownloadFileProgressAction({
      payload: {
        type: response.type,
        hasError: response.hasError
      }
    });
  }

  private static getFileLocation(response: any, payload: any): Action {
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      const fileLocationUrl = response.headers.get('Location');

      return PackageFilesActions.DownloadFileSuccessAction({
        payload: {
          fileLocationUrl: fileLocationUrl
        }
      });
    } else {
      return PackageFilesActions.LoadFileLocationAction({
        payload: {
          fileName: payload.fileName, packageId: payload.packageId
        }
      });
    }
  }
}
