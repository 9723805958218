import { Action, createReducer, on } from "@ngrx/store";

import { PagingResponseDataTableObjectModel } from "../../modules/shared/models/paging/paging-response-data-table-objects.model";
import * as ChangesActions from './changes.actions';
import { ChangesState, initialState } from "./changes.state";

export const changesFeatureKey = 'changes';

const changesReducer = createReducer<ChangesState>(
  initialState,
  on(ChangesActions.LoadPackagesAction, (state: ChangesState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ChangesActions.LoadPackagesSuccessAction, (state: ChangesState, { payload }) => {
    return {
      ...state,
      packages: payload,
      loading: false
    };
  }),
  on(ChangesActions.ScrollPackagesAction, (state: ChangesState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ChangesActions.ScrollPackagesSuccessAction, (state: ChangesState, { payload }) => {
    const packages = { ...payload };
    packages.content = state.packages.content.concat(payload.content);

    return {
      ...state,
      packages,
      loading: false
    };
  }),
  on(ChangesActions.CleanupPackagesAction, (state: ChangesState) => {
    return {
      packages: new PagingResponseDataTableObjectModel(),
      loading: true
    };
  })
);

export function ChangesReducer(state: ChangesState | undefined, action: Action): ChangesState {
  return changesReducer(state, action);
}
