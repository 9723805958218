import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Country } from '../../../../app/modules/shared/interfaces/country/country.interface';
import { CountriesService } from '../../../../app/modules/shared/services/countries/countries.service';
import { GenericErrorAction } from '../../shared/shared.actions';
import { CountryRegions } from './../../../modules/shared/enumerations/country-regions.enum';
import * as PackageRegistrationsActions from './package-registrations.actions';


@Injectable()
export class PackageRegistrationsEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly countriesService: CountriesService
  ) { }

  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(PackageRegistrationsActions.LoadCountriesAction),
    map((action) => action.term),
    switchMap((term) =>
      this.countriesService.get(term).pipe(
        map((countries: Array<Country>) => {
          return PackageRegistrationsActions.LoadCountriesSuccessAction({payload: countries});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getRegionCountries$ = createEffect(() => this.actions$.pipe(
    ofType(PackageRegistrationsActions.LoadRegionCountriesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.countriesService.getRegionCountries(payload).pipe(
        map((countries: Array<Country>) => {
          if (payload === CountryRegions.CE_REGION) {
            return PackageRegistrationsActions.LoadCERegionCountriesSuccessAction({countries});
          }

          if (payload === CountryRegions.FRENCH_BLOCK) {
            return PackageRegistrationsActions.LoadFrenchBlockCountriesSuccessAction({countries});
          }
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
