import { createAction, props } from '@ngrx/store';

import { Country } from '../../../modules/shared/interfaces/country/country.interface';
import { CustomerAutomaticDistribution } from '../../../modules/shared/interfaces/customer/customer-automatic-distribution';
import { PagingResponse } from '../../../modules/shared/interfaces/paging/paging-response.interface';
import { GetRegionsHttpResponse } from '../../../modules/shared/interfaces/region/get-regions-http-response.interface';
import { Region } from '../../../modules/shared/interfaces/region/region.interface';
import {
  AssignmentsForSave,
  RegionAssignmentsUpdateData,
  StoredRegionAssignments
} from '../../../modules/shared/interfaces/region/region-assignents-update.interface';
import { RegionRevision } from '../../../modules/shared/interfaces/region/region-revision.interface';

export const CREATE_REGION = '[PACKAGE-ASSIGNMENTS] CREATE_REGION';
export const CREATE_REGION_SUCCESS = '[PACKAGE-ASSIGNMENTS] CREATE_REGION_SUCCESS';
export const GET_REGIONS = '[PACKAGE-ASSIGNMENTS] GET_REGIONS';
export const GET_REGIONS_SUCCESS = '[PACKAGE-ASSIGNMENTS] GET_REGIONS_SUCCESS';
export const UPDATE_REGION = '[PACKAGE-ASSIGNMENTS] UPDATE_REGION';
export const UPDATE_REGION_SUCCESS = '[PACKAGE-ASSIGNMENTS] UPDATE_REGION_SUCCESS';
export const DELETE_REGION = '[PACKAGE-ASSIGNMENTS] DELETE_REGION';
export const DELETE_REGION_SUCCESS = '[PACKAGE-ASSIGNMENTS] DELETE_REGION_SUCCESS';
export const GET_REGION_ASSIGNMENTS_CHANGES = '[PACKAGE-ASSIGNMENTS] GET_REGION_ASSIGNMENTS_CHANGES';
export const SAVE_REGION_ASSIGNMENTS = '[PACKAGE-ASSIGNMENTS] SAVE_REGION_ASSIGNMENTS';
export const SAVE_REGION_ASSIGNMENTS_SUCCESS = '[PACKAGE-ASSIGNMENTS] SAVE_REGION_ASSIGNMENTS_SUCCESS';
export const GET_CUSTOMERS = '[PACKAGE-ASSIGNMENTS] GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = '[PACKAGE-ASSIGNMENTS] GET_CUSTOMERS_SUCCESS';
export const SCROLL_CUSTOMERS = '[PACKAGE-ASSIGNMENTS] SCROLL_CUSTOMERS';
export const SCROLL_CUSTOMERS_SUCCESS = '[PACKAGE-ASSIGNMENTS] SCROLL_CUSTOMERS_SUCCESS';
export const SAVE_PACKAGE_ASSIGNMENTS = '[PACKAGE-ASSIGNMENTS] SAVE_PACKAGE_ASSIGNMENTS';
export const CLEANUP_PACKAGE_ASSIGNMENTS = '[PACKAGE-ASSIGNMENTS] CLEANUP_PACKAGE_ASSIGNMENTS';
export const CLEANUP_PACKAGE_ASSIGNMENTS_WITHOUT_USER_ASSIGNMENTS_FORM = '[PACKAGE-ASSIGNMENTS] CLEANUP_PACKAGE_ASSIGNMENTS_WITHOUT_USER_ASSIGNMENTS_FORM';
export const UPDATE_REGIONS = '[PACKAGE-ASSIGNMENTS] UPDATE_REGIONS';

// Create region actions
export const CreateRegionAction =
    createAction(CREATE_REGION, props<{ payload: { packageId: number; region: Region } }>());

// Get regions actions
export const GetRegionsAction = createAction(GET_REGIONS, props<{ payload: { packageId: number } }>());

export const GetRegionsSuccessAction = createAction(
  GET_REGIONS_SUCCESS,
  props<{ regions: Array<GetRegionsHttpResponse>; unassignedCountries: Array<Country> }>()
);

// Update regions actions
export const UpdateRegionAction = createAction(UPDATE_REGION, props<{
    payload: {
        packageId: number;
        regionId: number;
        updatedRegion: Region;
    }
}>());

export const UpdateRegionSuccessAction = createAction(UPDATE_REGION_SUCCESS);

// Delete region actions
export const DeleteRegionAction = createAction(DELETE_REGION, props<{
    payload: {
        packageId: number;
        regionId: number;
    };
}>());

export const DeleteRegionSuccessAction = createAction(DELETE_REGION_SUCCESS, props<{
    regionId: number;
}>());

// Save assignments to store actions
export const SavePackageAssignmentsToStoreAction = createAction(
  SAVE_PACKAGE_ASSIGNMENTS,
  props<{ userAssignments: StoredRegionAssignments }>());

export const GetRegionAssignmentsChanges = createAction(
  GET_REGION_ASSIGNMENTS_CHANGES,
  props<{ userAssignmentsChanges: Array<RegionAssignmentsUpdateData> }>()
);

// Save Region assignments actions
export const SaveRegionAssignmentsAction = createAction(
  SAVE_REGION_ASSIGNMENTS,
  props<{
        payload: {
            packageId: number;
            regionAssignments: Array<AssignmentsForSave>;
        };
        shouldNotRedirect: boolean;
        shouldPublish: boolean;
    }>()
);

export const SaveRegionAssignmentsSuccessAction = createAction(SAVE_REGION_ASSIGNMENTS_SUCCESS);

// Get customers for automatic distribution
export const GetCustomersAction = createAction(
  GET_CUSTOMERS,
  props<{ payload: { term: string; countryCodes: string; page: number } }>());

export const GetCustomersSuccessAction = createAction(
  GET_CUSTOMERS_SUCCESS,
  props<{ payload: PagingResponse<CustomerAutomaticDistribution> }>()
);

// Scroll customers for automatic distribution
export const ScrollCustomersAction =
    createAction(SCROLL_CUSTOMERS, props<{ payload: { countryCodes: string; page: number } }>());

export const ScrollCustomersSuccess = createAction(
  SCROLL_CUSTOMERS_SUCCESS,
  props<{ payload: PagingResponse<CustomerAutomaticDistribution> }>()
);

export const UpdateRegions = createAction(UPDATE_REGIONS, props<{payload: Array<RegionRevision> }>());

// Clean up actions
export const CleanUpPackageAssignmentsAction = createAction(CLEANUP_PACKAGE_ASSIGNMENTS);

export const CleanUpPackageAssignmentsWithoutUserAssignmentsFormAction =
    createAction(CLEANUP_PACKAGE_ASSIGNMENTS_WITHOUT_USER_ASSIGNMENTS_FORM);
