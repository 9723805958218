// Http Param Constants
export const SEARCH_PARAM = "searchParameter";
export const PAGE = 'page';
export const SIZE = 'size';
export const EXCLUDE_CURRENT_USER_PARAM = "excludeCurrentUser";

// Http Header Constants
export const LDAP_GROUPS_HEADER = 'ldap-groups';
export const USERNAMES_HEADER = 'usernames';
export const EXCLUDE_USERNAMES_HEADER = 'exclude-usernames';

// HTTP Header LDAP_GROUPS values
export const HIGH_PRIVILEGED_SD = 'HIGH_PRIVILEGED_SD';
export const ADMIN_SD = 'ADMIN_SD';
export const LOW_PRIVILEGED_SD = 'LOW_PRIVILEGED_SD';
export const GENERAL = 'GENERAL';
export const ADMIN_PLATFORM = 'ADMIN_PLATFORM';
export const STI_ADMIN = 'STI_ADMIN';
export const STI_GENERAL = 'STI_GENERAL';


