import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Package } from '../../modules/shared/interfaces/package/package.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as OverviewActions from './overview.actions';

@Injectable()
export class OverviewEffects {
  constructor(private readonly actions$: Actions,
              private readonly packagesService: PackagesService) { }

  loadPackages$ = createEffect(() => this.actions$.pipe(
    ofType(OverviewActions.LoadPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.get(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<Package>) => {
          return OverviewActions.LoadPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(OverviewActions.CleanupPackagesAction());
        })
      )
    )
  ));

  scrollPackages$ = createEffect(() => this.actions$.pipe(
    ofType(OverviewActions.ScrollPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.get(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<Package>) => {
          return OverviewActions.ScrollPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
