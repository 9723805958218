import {
  HttpClient,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { CONTENT_TYPE } from '../../constants/http-headers/content-type.constants';
import { FileType } from '../../interfaces/file-type/file-type.interface';
import { PackageFileRevision } from '../../interfaces/package-file/package-file.interface';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private readonly http: HttpClient) {
  }

  get(): Observable<Array<FileType>> {
    return this.http.get<Array<FileType>>(`${environment.apiUrl}/file-types`);
  }

  uploadPackageFile(id: number, file: FormData, chunkIndex: number, totalChunks: number): Observable<any> {
    const chunkCheckSum = file.get('chunkCheckSum');

    const headers = new HttpHeaders({
      chunkIndex: chunkIndex.toString(),
      totalChunks: totalChunks.toString(),
      chunkCheckSum: chunkCheckSum.toString()
    });

    const req = new HttpRequest('POST', `${environment.apiUrl}/packages/${id}/files`, file, {
      headers,
      reportProgress: true
    });

    return this.http.request(req);
  }

  replacePackageFile(id: number, file: FormData, chunkIndex: number, totalChunks: number): Observable<any> {
    const chunkCheckSum = file.get('chunkCheckSum');

    const headers = new HttpHeaders({
      chunkIndex: chunkIndex.toString(),
      totalChunks: totalChunks.toString(),
      chunkCheckSum: chunkCheckSum.toString()
    });

    const req = new HttpRequest('PUT', `${environment.apiUrl}/packages/${id}/files/replace`, file, {
      headers,
      reportProgress: true
    });

    return this.http.request(req);
  }

  downloadFiles(packageId: number, ids: string): Observable<any> {
    let customHeaders;

    if (ids) {
      customHeaders = {
        'Content-Type': CONTENT_TYPE.APPLICATION_JSON,
        ids
      };
    } else {
      customHeaders = {'Content-Type': CONTENT_TYPE.APPLICATION_JSON};
    }

    return this.http.get(`${environment.apiUrl}/packages/${packageId}/files`,
      {
        headers: new HttpHeaders(customHeaders),
        reportProgress: true,
        observe: 'events'
      });
  }

  getFileLocation(fileName: string, packageId: number): Observable<any> {
    return this.http.head<any>(`${environment.apiUrl}/packages/${packageId}/files/temp/${fileName}`, {
      observe: 'response'
    });
  }

  deleteFile(packageId: number, fileId: number): Observable<any> {
    const customHeader = {
      ids: fileId.toString(),
      'Content-Type': CONTENT_TYPE.APPLICATION_JSON
    };
    const req = new HttpRequest('DELETE', `${environment.apiUrl}/packages/${packageId}/files`,
      {headers: new HttpHeaders(customHeader)});

    return this.http.request(req);
  }

  previewFile(packageId: number, fileId: number): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Blob>(`${environment.apiUrl}/packages/${packageId}/files/${fileId}/preview`, httpOptions);
  }

  replaceFileInRevision(
    packageId: number,
    file: FormData,
    chunkIndex: number,
    totalChunks: number,
    fileId: number,
    revisionUuid: string): Observable<any> {
    const chunkCheckSum = file.get('chunkCheckSum');

    const headers = new HttpHeaders({
      chunkIndex: chunkIndex.toString(),
      totalChunks: totalChunks.toString(),
      chunkCheckSum: chunkCheckSum.toString(),
      'revision-uuid': revisionUuid
    });

    return this.http.put<any>(
      `${environment.apiUrl}/packages/${packageId}/file-revisions/${fileId}`,
      file,
      {headers});
  }

  deleteFileInRevision(
    packageId: number,
    fileId: number,
    revisionUuid: string): Observable<Array<PackageFileRevision>> {
    const headers = new HttpHeaders({
      'revision-uuid': revisionUuid,
      'Content-Type': 'application/json'
    });

    return this.http.delete<Array<PackageFileRevision>>(
      `${environment.apiUrl}/packages/${packageId}/file-revisions/${fileId}`,
      {headers});
  }

  createFileRevision(
    packageId: number,
    revisionUuid: string,
    file: FormData,
    chunkIndex: number,
    totalChunks: number): Observable<any> {
    const chunkCheckSum = file.get('chunkCheckSum');

    const headers = new HttpHeaders({
      chunkIndex: chunkIndex.toString(),
      totalChunks: totalChunks.toString(),
      chunkCheckSum: chunkCheckSum.toString(),
      'revision-uuid': revisionUuid
    });

    const req = new HttpRequest('POST', `${environment.apiUrl}/packages/${packageId}/file-revisions`, file, {
      headers,
      reportProgress: true
    });

    return this.http.request(req);
  }

  revertFileRevision(
    packageId: number,
    fileRevisionId: number,
    revisionUuid: string): Observable<Array<PackageFileRevision>> {
    const headers = new HttpHeaders({'revision-uuid': revisionUuid});

    return this.http.put<Array<PackageFileRevision>>(
      `${environment.apiUrl}/packages/${packageId}/file-revisions/${fileRevisionId}/undo`, null, {headers});
  }

  previewFileRevision(packageId: number, fileId: number): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<Blob>(
      `${environment.apiUrl}/packages/${packageId}/file-revisions/${fileId}/preview`, httpOptions);
  }
}
