import { Action, createReducer, on } from '@ngrx/store';

import { PagingResponsePackageModel } from '../../modules/shared/models/paging/paging-response-package.model';
import * as OverviewActions from './overview.actions';
import { initialState, OverviewState } from './overview.state';

export const overviewFeatureKey = 'overview';

const overviewReducer = createReducer<OverviewState>(
  initialState,
  on(OverviewActions.LoadPackagesAction, (state: OverviewState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(OverviewActions.LoadPackagesSuccessAction, (state: OverviewState, { payload }) => {
    return {
      ...state,
      packages: payload,
      loading: false
    };
  }),
  on(OverviewActions.ScrollPackagesAction, (state: OverviewState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(OverviewActions.ScrollPackagesSuccessAction, (state: OverviewState, { payload }) => {
    const packages = {...payload};
    packages.content = state.packages.content.concat(payload.content);

    return {
      ...state,
      packages,
      loading: false
    };
  }),
  on(OverviewActions.CleanupPackagesAction, (state: OverviewState) => {
    return {
      packages: new PagingResponsePackageModel(),
      loading: true
    };
  })
);

export function OverviewReducer(state: OverviewState | undefined, action: Action): OverviewState {
  return overviewReducer(state, action);
}
