import { createAction, props } from '@ngrx/store';
import { TableDataObject } from 'src/app/modules/shared/interfaces/table-data-object/table-data-object.interface';

import { PackageHttpRequest } from '../../modules/shared/interfaces/package/package-http-request.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';

export const LOAD_PACKAGES = '[CHANGES] LOAD_PACKAGES';
export const LOAD_PACKAGES_SUCCESS = '[CHANGES] LOAD_PACKAGES_SUCCESS';
export const SCROLL_PACKAGES = '[CHANGES] SCROLL_PACKAGES';
export const SCROLL_PACKAGES_SUCCESS = '[CHANGES] SCROLL_PACKAGES_SUCCESS';
export const CLEANUP_PACKAGES = '[CHANGES] CLEAN_PACKAGES';

export const LoadPackagesAction = createAction(LOAD_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const LoadPackagesSuccessAction =
  createAction(LOAD_PACKAGES_SUCCESS, props<{ payload: PagingResponse<TableDataObject> }>());

export const ScrollPackagesAction = createAction(SCROLL_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const ScrollPackagesSuccessAction =
  createAction(SCROLL_PACKAGES_SUCCESS, props<{ payload: PagingResponse<TableDataObject> }>());

export const CleanupPackagesAction = createAction(CLEANUP_PACKAGES);
