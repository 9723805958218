import { Action, createReducer, on } from '@ngrx/store';
import { PagingResponseDataTableObjectModel } from 'src/app/modules/shared/models/paging/paging-response-data-table-objects.model';

import * as InboxActions from './inbox.actions';
import { InboxState, initialState } from './inbox.state';

export const inboxFeatureKey = 'inbox';

const inboxReducer = createReducer<InboxState>(
  initialState,
  on(InboxActions.LoadPackagesAction, (state: InboxState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(InboxActions.LoadPackagesSuccessAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      packages: payload,
      loading: false
    };
  }),
  on(InboxActions.ScrollPackagesAction, (state: InboxState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(InboxActions.ScrollPackagesSuccessAction, (state: InboxState, { payload }) => {
    const packages = {...payload};
    packages.content = state.packages.content.concat(payload.content);

    return {
      ...state,
      packages,
      loading: false
    };
  }),
  on(InboxActions.GetPendingPackagesCountSuccessAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      pendingPackagesCount: payload
    };
  }),
  on(InboxActions.CleanupPackagesAction, (state: InboxState) => {
    return {
      packages: new PagingResponseDataTableObjectModel(),
      pendingPackagesCount: null,
      loading: true
    };
  })
);

export function InboxReducer(state: InboxState | undefined, action: Action): InboxState {
  return inboxReducer(state, action);
}
