import { createAction, props } from '@ngrx/store';

import { Article } from '../../modules/shared/interfaces/article/article';
import { NewPackage } from '../../modules/shared/interfaces/package/package.interface';
import { Product } from '../../modules/shared/interfaces/product/product.interface';
import { ProductFamily } from '../../modules/shared/interfaces/product-family/product-family.interface';

export const LOAD_PRODUCT_FAMILY = '[NEW-PACKAGE] LOAD_PRODUCT_GROUPS';
export const LOAD_PRODUCT_FAMILY_SUCCESS = '[NEW-PACKAGE] LOAD_PRODUCT_GROUPS_SUCCESS';
export const LOAD_PRODUCTS = '[NEW-PACKAGE] LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = '[NEW-PACKAGE] LOAD_PRODUCTS_SUCCESS';
export const LOAD_ARTICLES = '[NEW-PACKAGE] LOAD_ARTICLES';
export const LOAD_ARTICLES_SUCCESS = '[NEW-PACKAGE] LOAD_ARTICLES_SUCCESS';
export const CREATE_PACKAGE = '[NEW_PACKAGE] CREATE_PACKAGE';
export const CREATE_PACKAGE_SUCCESS = '[NEW_PACKAGE] CREATE_PACKAGE_SUCCESS';
export const CLEANUP_NEW_PACKAGE = '[NEW_PACKAGE] CLEANUP_NEW_PACKAGE';

// Product Groups actions
export const LoadProductFamilyAction = createAction(LOAD_PRODUCT_FAMILY, props<{ term: string }>());

export const LoadProductFamilySuccessAction =
  createAction(LOAD_PRODUCT_FAMILY_SUCCESS, props<{ payload: Array<ProductFamily> }>());

// Products actions
export const LoadProductsAction = createAction(LOAD_PRODUCTS, props<{ term: string; productFamily: string }>());

export const LoadProductsSuccessAction = createAction(LOAD_PRODUCTS_SUCCESS, props<{ payload: Array<Product> }>());

// Articles actions
export const LoadArticlesAction =
  createAction(LOAD_ARTICLES, props<{ id: string; productFamily: string; productType: string }>());

export const LoadArticlesSuccessAction = createAction(LOAD_ARTICLES_SUCCESS, props<{ payload: Array<Article> }>());

// Create package actions
export const CreatePackageAction = createAction(CREATE_PACKAGE, props<{ payload: NewPackage }>());

export const CreatePackageSuccessAction = createAction(CREATE_PACKAGE_SUCCESS);

// Clean up action
export const CleanUpNewPackageAction = createAction(CLEANUP_NEW_PACKAGE);
