export const environment = {
  production: false,
  apiUrl: window['env']['apiUrl'] || 'https://sd.iomtdev.bbraun.io/api/v1',
  apiV2Url: window['env']['apiV2Url'] || 'https://sd.iomtdev.bbraun.io/api/v2',
  pmUrl: window['env']['pmUrl'] || 'https://pm.iomtdev.bbraun.io',
  adminUrl: window['env']['adminUrl'] || 'https://portal.iomtdev.bbraun.io/admin/',
  dfmUrl: window['env']['dfmUrl'] || 'https://portal.iomtdev.bbraun.io',
  loginUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/authorize',
  logoutUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/logout',
  issuer: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/v2.0',
  clientId: '0058bfe5-e446-4372-927b-bb0bbca8e020',
  scope: 'openid offline_access profile email',
  trackingUi: {
    enable: true,
    reportingUrl: 'https://eum-green-saas.instana.io',
  },
  instanaApiKey: window['env']['instanaApiKey'] || '',
};
