import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PRIVILEGED_USERS } from '../../../../app/modules/shared/constants/user/user.constants';
import { LdapUser } from '../../../../app/modules/shared/interfaces/ldap-user/ldap-user.interface';
import { UsersService } from '../../../../app/modules/shared/services/users/users.service';
import { GenericErrorAction } from '../../shared/shared.actions';
import * as PackageManagersActions from './package-managers.actions';


@Injectable()
export class PackageManagersEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly usersService: UsersService
  ) { }

  loadApprovers$ = createEffect(() => this.actions$.pipe(
    ofType(PackageManagersActions.LoadApproversAction),
    map((action) => action.term),
    switchMap((term) =>
      this.usersService.getUsers(term, true).pipe(
        map((approvers: Array<LdapUser>) => {
          return PackageManagersActions.LoadApproversSuccessAction({payload: approvers});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  loadPrivilegedUsers$ = createEffect(() => this.actions$.pipe(
    ofType(PackageManagersActions.LoadPrivilegedUsersAction),
    map((action) => action.term),
    switchMap((term) =>
      this.usersService.getUsers(term, null, PRIVILEGED_USERS).pipe(
        map((users: Array<LdapUser>) => {
          return PackageManagersActions.LoadPrivilegedUsersSuccessAction({payload: users});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
