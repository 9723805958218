import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuditExportData } from '../../modules/shared/interfaces/audit-exports/audit-export-data.interface';
import { AuditExportsTablePackage } from '../../modules/shared/interfaces/audit-exports/audit-export-table-package.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { GenericErrorAction } from '../shared/shared.actions';
import { AuditExportsService } from './../../modules/shared/services/audit-exports/audit-exports.service';
import * as AuditExport from './audit-exports.actions';

@Injectable()
export class AuditExportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly auditExportsService: AuditExportsService
  ) { }

  loadAuditExportPackages$ = createEffect(() => this.actions$.pipe(
    ofType(AuditExport.LoadAuditExportsPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.auditExportsService.getAuditExportsPackages(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<AuditExportsTablePackage>) => {
          return AuditExport.LoadAuditExportsPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(AuditExport.CleanupAuditExportsPackagesAction());
        })
      )
    )
  ));

  scrollAuditExportPackages$ = createEffect(() => this.actions$.pipe(
    ofType(AuditExport.ScrollAuditExportsPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.auditExportsService.getAuditExportsPackages(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<AuditExportsTablePackage>) => {
          return AuditExport.ScrollAuditExportsPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  loadPdfExport$ = createEffect(() => this.actions$.pipe(
    ofType(AuditExport.LoadAuditExportPdfDataAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.auditExportsService.getPdfExport(payload.packageId).pipe(
        map((pdfExport: Array<AuditExportData>) => {
          return AuditExport.LoadAuditExportPdfDataSuccessAction({payload: pdfExport});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
