import { createAction, props } from '@ngrx/store';

import { LdapUser } from '../../../../app/modules/shared/interfaces/ldap-user/ldap-user.interface';

export const LOAD_APPROVERS = '[PACKAGE-MANAGERS] LOAD_APPROVERS';
export const LOAD_APPROVERS_SUCCESS = '[PACKAGE-MANAGERS] LOAD_APPROVERS_SUCCESS';
export const LOAD_PRIVILEGED_USERS = '[PACKAGE-MANAGERS] LOAD_PRIVILEGED_USERS';
export const LOAD_PRIVILEGED_USERS_SUCCESS = '[PACKAGE-MANAGERS] LOAD_PRIVILEGED_USERS_SUCCESS';
export const CLEANUP_PACKAGE_MANAGERS = '[PACKAGE-MANAGERS] CLEANUP_PACKAGE_MANAGERS';

export const LoadApproversAction = createAction(LOAD_APPROVERS, props<{ term: string }>());

export const LoadApproversSuccessAction = createAction(LOAD_APPROVERS_SUCCESS, props<{ payload: Array<LdapUser> }>());

export const LoadPrivilegedUsersAction = createAction(LOAD_PRIVILEGED_USERS, props<{ term: string }>());

export const LoadPrivilegedUsersSuccessAction =
  createAction(LOAD_PRIVILEGED_USERS_SUCCESS, props<{ payload: Array<LdapUser> }>());

export const CleanUpPackageManagersAction = createAction(CLEANUP_PACKAGE_MANAGERS);


