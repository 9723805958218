import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TgConfirmDialogModule, TgFloatingButtonModule, TgNotificationModule } from '@bbraun/toolguide';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { SplashScreenModule } from './modules/shared/components/splash-screen/splash-screen.module';
import { HttpErrorInterceptor } from './modules/shared/interceptors/https.interceptor';
import { ManageHttpInterceptor } from './modules/shared/interceptors/manage-http.interceptor';
import { TranslocoRootModule } from './modules/transloco-root/transloco-root.module';
import { effects, reducers } from './store/app-state';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    AuthModule.forRoot(),
    TgConfirmDialogModule,
    TgNotificationModule,
    TgFloatingButtonModule,
    MatDialogModule,
    SplashScreenModule,
    TranslocoRootModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production
    })
  ],
  providers: [
    {
      provide:
            HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide:
            HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

