import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthFacade } from '../../../store/auth/auth.facade';

@Injectable({
  providedIn: 'root'
})
export class NewPackageGuard implements CanActivate {

  constructor(
        private readonly authFacade: AuthFacade,
        private readonly router: Router) {
  }

  /**
   * Allows only admins for Software Distribution or high privileged users to go to the new package screen
   */
  canActivate(): Observable<boolean> {
    this.authFacade.getUserInfo();

    return this.authFacade.userInfo$
      .pipe(
        first(res => res !== null),
        map((rights) => {
          if (rights.softwareDistributionAdmin || rights.highPrivileged) {
            return true;
          }

          this.router.navigateByUrl('/');
          return false;
        })
      );
  }
}
