import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { UserInfo } from '../../interfaces/user/user-info.interface';
import { HttpBaseService } from '../http/http.base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpBaseService {

  constructor(private readonly http: HttpClient) {
    super();
  }

  getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.apiUrl}/user-info`);
  }
}
