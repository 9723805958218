import { Package } from '../../modules/shared/interfaces/package/package.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PagingResponsePackageModel } from '../../modules/shared/models/paging/paging-response-package.model';

export interface OverviewState {
  packages: PagingResponse<Package>;
  loading: boolean;
}

export const initialState: OverviewState = {
  packages: new PagingResponsePackageModel(),
  loading: true
};
