import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { INBOX_TABLE } from '../../constants/table/table.constants';
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';
import { TableDataObject } from '../../interfaces/table-data-object/table-data-object.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';
import { HttpBaseService } from '../http/http.base.service';

@Injectable({
  providedIn: 'root'
})
export class InboxService extends HttpBaseService {

  constructor(private readonly http: HttpClient) {
    super();
  }

  get(page: number, sort: string, searchParameter: string): Observable<PagingResponse<TableDataObject>> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
      .append('size', INBOX_TABLE.size.toString())
      .append('page', page.toString())
      .append('sort', sort.toString())
      .append('searchParameter', searchParameter);

    return this.http.get<PagingResponse<TableDataObject>>(`${environment.apiUrl}/inbox`, { params });
  }
}
