import { AuditExportsTablePackage } from '../../interfaces/audit-exports/audit-export-table-package.interface';
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';

export class PagingResponseAuditPackageModel implements PagingResponse<AuditExportsTablePackage> {
  content: Array<AuditExportsTablePackage>;
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageSize: number;
    pageNumber: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;

  constructor(
    content?: Array<AuditExportsTablePackage>,
    pageable?: {
      sort: {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
      };
      offset: number;
      pageSize: number;
      pageNumber: number;
      paged: boolean;
      unpaged: boolean;
    },
    totalPages?: number,
    totalElements?: number,
    last?: boolean,
    first?: boolean,
    sort?: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    },
    numberOfElements?: number,
    size?: number,
    number?: number,
    empty?: boolean
  ) {
    this.content = content ? content : [];
    this.pageable = pageable ? pageable : null;
    this.totalPages = totalPages ? totalPages : 0;
    this.totalElements = totalElements ? totalElements : 0;
    this.last = last ? last : null;
    this.first = first ? first : null;
    this.sort = sort ? sort : null;
    this.numberOfElements = numberOfElements ? numberOfElements : null;
    this.size = size ? size : null;
    this.number = number ? number : null;
    this.empty = empty ? empty : null;
  }
}
