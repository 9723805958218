import { Country } from '../../../../app/modules/shared/interfaces/country/country.interface';

export interface PackageRegistrationsState {
  countries: Array<Country>;
  ceRegionCountries: Array<Country>;
  frenchBlockCountries: Array<Country>;
}

export const initialState: PackageRegistrationsState = {
  countries: [],
  ceRegionCountries: [],
  frenchBlockCountries: []
};
