import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ProductFamily } from '../../interfaces/product-family/product-family.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFamilyService {

  constructor(private readonly http: HttpClient) { }

  get(name: string): Observable<Array<ProductFamily>> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
      .append('name', name);

    if (name) {
      return this.http.get<Array<ProductFamily>>(`${environment.apiUrl}/product-families`, { params });
    } else {
      return this.http.get<Array<ProductFamily>>(`${environment.apiUrl}/product-families`);
    }
  }
}
