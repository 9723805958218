import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TableDataObject } from 'src/app/modules/shared/interfaces/table-data-object/table-data-object.interface';

import { PendingPackagesCount } from "../../modules/shared/interfaces/package/pending-packages-count.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { InboxService } from '../../modules/shared/services/inbox/inbox.service';
import { PackagesService } from "../../modules/shared/services/packages/packages.service";
import { GenericErrorAction } from '../shared/shared.actions';
import * as InboxActions from './inbox.actions';

@Injectable()
export class InboxEffects {
  constructor(private readonly actions$: Actions,
              private readonly inboxService: InboxService,
              private readonly packagesService: PackagesService) { }

  loadPackages$ = createEffect(() => this.actions$.pipe(
    ofType(InboxActions.LoadPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.inboxService.get(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<TableDataObject>) => {
          return InboxActions.LoadPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(InboxActions.CleanupPackagesAction());
        })
      )
    )
  ));

  scrollPackages$ = createEffect(() => this.actions$.pipe(
    ofType(InboxActions.ScrollPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.inboxService.get(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<TableDataObject>) => {
          return InboxActions.ScrollPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  pendingPackagesCount$ = createEffect(() => this.actions$.pipe(
    ofType(InboxActions.GetPendingPackagesCountAction),
    map((action) => action),
    switchMap((payload) =>
      this.packagesService.getPendingPackagesCount().pipe(
        map((payload: PendingPackagesCount) => {
          return InboxActions.GetPendingPackagesCountSuccessAction( {payload} );
        }),
        catchError((error: Error) => {
          return of(InboxActions.CleanupPackagesAction());
        })
      )
    )
  ));
}
