import { ArchivedPackage } from "../../modules/shared/interfaces/package/archived-package.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import {
  PagingResponseArchivedPackagesModel
} from "../../modules/shared/models/paging/archived-packages-paging-response.model";

export interface ArchiveState {
  archivedPackages: PagingResponse<ArchivedPackage>;
  loading: boolean;
}

export const initialState: ArchiveState = {
  archivedPackages: new PagingResponseArchivedPackagesModel(),
  loading: true
};
