import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';
import { HttpBaseService } from '../http/http.base.service';
import { AUDIT_EXPORT_TABLE } from './../../constants/table/table.constants';
import { AuditExportData } from './../../interfaces/audit-exports/audit-export-data.interface';
import { AuditExportsTablePackage } from './../../interfaces/audit-exports/audit-export-table-package.interface';

@Injectable({
  providedIn: 'root'
})
export class AuditExportsService extends HttpBaseService {

  constructor(private readonly http: HttpClient) {
    super();
  }

  getAuditExportsPackages(page: number, sort: string, searchParameter: string)
    : Observable<PagingResponse<AuditExportsTablePackage>> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
      .append('size', AUDIT_EXPORT_TABLE.size.toString())
      .append('page', page.toString())
      .append('sort', sort.toString())
      .append('searchParameter', searchParameter);

    return this.http.get<PagingResponse<AuditExportsTablePackage>>(`${environment.apiUrl}/packages/audit`, { params });
  }

  getPdfExport(packageId: number)
    : Observable<Array<AuditExportData>> {
    return this.http.get<Array<AuditExportData>>(`${environment.apiUrl}/packages/${packageId}/audit`);
  }
}
