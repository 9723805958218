import { createAction, props } from '@ngrx/store';

import { Package } from '../../modules/shared/interfaces/package/package.interface';
import { PackageHttpRequest } from '../../modules/shared/interfaces/package/package-http-request.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';

export const LOAD_PACKAGES = '[OVERVIEW] LOAD_PACKAGES';
export const LOAD_PACKAGES_SUCCESS = '[OVERVIEW] LOAD_PACKAGES_SUCCESS';
export const SCROLL_PACKAGES = '[OVERVIEW] SCROLL_PACKAGES';
export const SCROLL_PACKAGES_SUCCESS = '[OVERVIEW] SCROLL_PACKAGES_SUCCESS';
export const CLEANUP_PACKAGES = '[OVERVIEW] CLEAN_PACKAGES';

export const LoadPackagesAction = createAction(LOAD_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const LoadPackagesSuccessAction =
  createAction(LOAD_PACKAGES_SUCCESS, props<{ payload: PagingResponse<Package> }>());

export const ScrollPackagesAction = createAction(SCROLL_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const ScrollPackagesSuccessAction =
  createAction(SCROLL_PACKAGES_SUCCESS, props<{ payload: PagingResponse<Package> }>());

export const CleanupPackagesAction = createAction(CLEANUP_PACKAGES);
