import { LdapUser } from '../../../../app/modules/shared/interfaces/ldap-user/ldap-user.interface';

export interface PackageManagersState {
  approvers: Array<LdapUser>;
  privilegedUsers: Array<LdapUser>;
}

export const initialState: PackageManagersState = {
  approvers: [],
  privilegedUsers: []
};
