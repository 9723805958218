import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { TableDataObject } from "src/app/modules/shared/interfaces/table-data-object/table-data-object.interface";

import { Package } from "../../modules/shared/interfaces/package/package.interface";
import { PagingResponse } from "../../modules/shared/interfaces/paging/paging-response.interface";
import { RevisionsService } from "../../modules/shared/services/revisions/revisions.service";
import { GenericErrorAction } from "../shared/shared.actions";
import * as ChangesActions from './changes.actions';

@Injectable()
export class ChangesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) { }

  loadPackages$ = createEffect(() => this.actions$.pipe(
    ofType(ChangesActions.LoadPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionsService.getRevisions(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<TableDataObject>) => {
          return ChangesActions.LoadPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(ChangesActions.CleanupPackagesAction());
        })
      )
    )
  ));

  scrollPackages$ = createEffect(() => this.actions$.pipe(
    ofType(ChangesActions.ScrollPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionsService.getRevisions(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<TableDataObject>) => {
          return ChangesActions.ScrollPackagesSuccessAction({ payload: packages });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
