import { createAction, props } from '@ngrx/store';

import { FstPreview } from '../../modules/shared/interfaces/import-fst/fst-preview.interface';
import { LdapUser } from '../../modules/shared/interfaces/ldap-user/ldap-user.interface';

export const GET_FST_PREVIEW = '[IMPORT-FST] GET_FST_PREVIEW';
export const GET_FST_PREVIEW_SUCCESS = '[IMPORT-FST] GET_FST_PREVIEW_SUCCESS';
export const GET_FST_PREVIEW_ERROR = '[IMPORT-FST] GET_FST_PREVIEW_ERROR';
export const IMPORT_FST_CHANGES = '[IMPORT-FST] - IMPORT_FST_CHANGES';
export const CLEANUP_FST = '[IMPORT-FST] CLEANUP_FST';
export const CLEANUP_IMPORTED_FST = '[IMPORT-FST] CLEANUP_IMPORTED_FST';

export const GetFstPreviewAction = createAction(
  GET_FST_PREVIEW,
  props<{ payload: { packageId: number; regionId: number; template: FormData } }>()
);

export const GetFstPreviewSuccessAction = createAction(
  GET_FST_PREVIEW_SUCCESS,
  props<{ fstsPreview: Array<FstPreview> }>()
);

export const GetFstPreviewErrorAction = createAction(GET_FST_PREVIEW_ERROR);

export const ImportFstChangesAction = createAction(
  IMPORT_FST_CHANGES,
  props<{ regionId: number; fstsPreviewFiltered: Array<LdapUser> }>()
);

export const CleanupFstAction = createAction(CLEANUP_FST);

export const CleanupImportedFstAction = createAction(CLEANUP_IMPORTED_FST);
