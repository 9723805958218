export const INBOX_TABLE = {
  page: 0,
  size: 20,
  sort: 'softwareReleaseDate,desc',
  searchTerm: ''
};

export const ARCHIVE_TABLE = {
  page: 0,
  size: 20,
  sort: 'archivingDate,desc',
  searchTerm: ''
};

export const AUDIT_EXPORT_TABLE = {
  page: 0,
  size: 20,
  sort: 'productType,desc',
  searchTerm: ''
};
