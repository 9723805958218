import { Action, createReducer, on } from "@ngrx/store";

import { PagingResponseUsersModel } from "../../modules/shared/models/paging/paging-response-users.model";
import * as GlobalUserReplacementActions from './global-user-replacement.actions';
import { GlobalUserReplacementState, initialState } from "./global-user-replacement.state";


export const globalUserReplacementFeatureKey = 'globalUserReplacement';

const globalUserReplacementReducer = createReducer<GlobalUserReplacementState>(
  initialState,
  on(GlobalUserReplacementActions.LoadUsersSuccessAction, (state: GlobalUserReplacementState, {payload}) => {
    const response = {...payload};

    if (!payload.first) {
      response.content = state.users.content.concat(payload.content);
    }

    return {
      ...state,
      users: response
    };
  }),
  on(GlobalUserReplacementActions.GetUserAssignmentsAction, (state: GlobalUserReplacementState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(GlobalUserReplacementActions.GetUserAssignmentsSuccessAction, (state: GlobalUserReplacementState, {payload}) => {
    return {
      ...state,
      userAssignments: payload,
      loading: false
    };
  }),
  on(GlobalUserReplacementActions.CleanUpAction, (state: GlobalUserReplacementState) => {
    return {
      ...state,
      users: new PagingResponseUsersModel(),
      userAssignments: []
    };
  })
);

export function GlobalUserReplacementReducer(state: GlobalUserReplacementState | undefined, action: Action):
  GlobalUserReplacementState {
  return globalUserReplacementReducer(state, action);
}
