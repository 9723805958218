import { LdapUser } from '../../modules/shared/interfaces/ldap-user/ldap-user.interface';
import { PackageDetailsResponse } from '../../modules/shared/interfaces/package/package-details-response.interface';
import { PackageUserRole } from '../../modules/shared/interfaces/package-user-role/package-user-role.interface';
import { PackageDetailsModel } from '../../modules/shared/models/packages/package-details.model';

export interface PackageDetailsState {
  actionId: number;
  details: PackageDetailsResponse;
  allUsers: Array<LdapUser>;
  assignedUserRoles: Array<PackageUserRole>;
  isFormDisabled: boolean;
}

export const initialState: PackageDetailsState = {
  actionId: null,
  allUsers: [],
  details: new PackageDetailsModel(),
  assignedUserRoles: [],
  isFormDisabled: false
};
