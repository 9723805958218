import { Action, createReducer, on } from '@ngrx/store';

import * as ImportFstActions from './import-fst.actions';
import { ImportFstState, initialState } from './import-fst.state';

export const importFstFeatureKey = 'importFst';

const importFstReducer = createReducer<ImportFstState>(
  initialState,
  on(ImportFstActions.GetFstPreviewAction, (state: ImportFstState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ImportFstActions.GetFstPreviewSuccessAction, (state: ImportFstState, {fstsPreview}) => {
    return {
      ...state,
      fstsPreview,
      loading: false
    };
  }),
  on(ImportFstActions.GetFstPreviewErrorAction, (state: ImportFstState) => {
    return {
      ...state,
      fstsPreview: null,
      loading: false
    };
  }),
  on(ImportFstActions.CleanupFstAction, (state: ImportFstState) => {
    return {
      ...state,
      fstsPreview: [],
      loading: false
    };
  }),
  on(ImportFstActions.ImportFstChangesAction, (state: ImportFstState, {fstsPreviewFiltered, regionId}) => {
    return {
      ...state,
      fstsPreviewFiltered,
      regionId
    };
  }),
  on(ImportFstActions.CleanupImportedFstAction, (state: ImportFstState) => {
    return {
      ...state,
      fstsPreview: [],
      loading: false,
      fstsPreviewFiltered: [],
      regionId: null
    };
  })
);

export function ImportFstReducer(state: ImportFstState | undefined,
  action: Action): ImportFstState {
  return importFstReducer(state, action);
}
