import { ActionReducerMap } from '@ngrx/store';

import { ArchiveEffects } from "./archive/archive.effects";
import { ArchiveReducer } from "./archive/archive.reducer";
import { ArchiveState } from "./archive/archive.state";
import { AuditExportsEffects } from './audit-exports/audit-exports.effects';
import { AuditExportsReducer } from './audit-exports/audit-exports.reducer';
import { AuditExportsState } from './audit-exports/audit-exports.state';
import { AuthEffects } from './auth/auth.effects';
import { AuthReducer } from './auth/auth.reducer';
import { AuthState } from './auth/auth.state';
import { ChangesEffects } from './changes/changes.effects';
import { ChangesReducer } from './changes/changes.reducer';
import { ChangesState } from './changes/changes.state';
import { EditRegionDetailsEffects } from './edit-region-details/edit-region-details.effects';
import { EditRegionDetailsReducer } from './edit-region-details/edit-region-details.reducer';
import { EditRegionDetailsState } from './edit-region-details/edit-region-details.state';
import { GlobalUserReplacementEffects } from "./global-user-replacement/global-user-replacement.effects";
import { GlobalUserReplacementReducer } from "./global-user-replacement/global-user-replacement.reducer";
import { GlobalUserReplacementState } from "./global-user-replacement/global-user-replacement.state";
import { ImportFromPackageEffects } from './import-from-package/import-from-package.effects';
import { ImportFromPackageReducer } from './import-from-package/import-from-package.reducer';
import { ImportFromPackageState } from './import-from-package/import-from-package.state';
import { ImportFstEffects } from './import-fst/import-fst.effects';
import { ImportFstReducer } from './import-fst/import-fst.reducer';
import { ImportFstState } from './import-fst/import-fst.state';
import { InboxEffects } from './inbox/inbox.effects';
import { InboxReducer } from './inbox/inbox.reducer';
import { InboxState } from './inbox/inbox.state';
import { NewPackageEffects } from './new-package/new-package.effects';
import { NewPackageReducer } from './new-package/new-package.reducer';
import { NewPackageState } from './new-package/new-package.state';
import { OverviewEffects } from './overview/overview.effects';
import { OverviewReducer } from './overview/overview.reducer';
import { OverviewState } from './overview/overview.state';
import { PackageAssignmentsEffects } from './package-details/package-assignments/package-assignments.effects';
import { PackageAssignmentsReducer } from './package-details/package-assignments/package-assignments.reducer';
import { PackageAssignmentsState } from './package-details/package-assignments/package-assignments.state';
import { PackageDetailsEffects } from './package-details/package-details.effects';
import { PackageDetailsReducer } from './package-details/package-details.reducer';
import { PackageDetailsState } from './package-details/package-details.state';
import { PackageFilesEffects } from './package-details/package-files/package-files.effects';
import { PackageFilesReducer } from './package-details/package-files/package-files.reducer';
import { PackageFilesState } from './package-details/package-files/package-files.state';
import { PackageManagersEffects } from './package-details/package-managers/package-managers.effects';
import { PackageManagersReducer } from './package-details/package-managers/package-managers.reducer';
import { PackageManagersState } from './package-details/package-managers/package-managers.state';
import { PackageRegistrationsEffects } from './package-details/package-registrations/package-registrations.effects';
import { PackageRegistrationsReducer } from './package-details/package-registrations/package-registrations.reducer';
import { PackageRegistrationsState } from './package-details/package-registrations/package-registrations.state';
import { SharedEffects } from './shared/shared.effects';

export interface AppState {
  auth: AuthState;
  inbox: InboxState;
  newPackage: NewPackageState;
  packageDetails: PackageDetailsState;
  packageAssignments: PackageAssignmentsState;
  packageFiles: PackageFilesState;
  overview: OverviewState;
  editRegionDetails: EditRegionDetailsState;
  importFromPackage: ImportFromPackageState;
  importFst: ImportFstState;
  packageRegistrations: PackageRegistrationsState;
  packageManagers: PackageManagersState;
  globalUserReplacement: GlobalUserReplacementState;
  archive: ArchiveState;
  auditExports: AuditExportsState;
  changes: ChangesState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: AuthReducer,
  inbox: InboxReducer,
  newPackage: NewPackageReducer,
  packageDetails: PackageDetailsReducer,
  packageAssignments: PackageAssignmentsReducer,
  packageFiles: PackageFilesReducer,
  overview: OverviewReducer,
  editRegionDetails: EditRegionDetailsReducer,
  importFromPackage: ImportFromPackageReducer,
  importFst: ImportFstReducer,
  packageRegistrations: PackageRegistrationsReducer,
  packageManagers: PackageManagersReducer,
  globalUserReplacement: GlobalUserReplacementReducer,
  archive: ArchiveReducer,
  auditExports: AuditExportsReducer,
  changes: ChangesReducer
};

export const effects = [
  AuthEffects,
  InboxEffects,
  NewPackageEffects,
  PackageDetailsEffects,
  PackageAssignmentsEffects,
  PackageFilesEffects,
  SharedEffects,
  OverviewEffects,
  EditRegionDetailsEffects,
  ImportFromPackageEffects,
  ImportFstEffects,
  PackageRegistrationsEffects,
  PackageManagersEffects,
  GlobalUserReplacementEffects,
  ArchiveEffects,
  AuditExportsEffects,
  ChangesEffects
];
