import { Country } from '../../interfaces/country/country.interface';

export class RegionDetailsModel {
  name: string;
  assignedCountries: Array<Country>;
  unassignedCountries: Array<Country>;
  existingRegionNames: Array<string>;

  constructor(name?: string,
    assignedCountries?: Array<Country>,
    unassignedCountries?: Array<Country>,
    existingRegionNames?: Array<string>) {
    this.name = name ? name : null;
    this.assignedCountries = assignedCountries ? assignedCountries : [];
    this.unassignedCountries = unassignedCountries ? unassignedCountries : [];
    this.existingRegionNames = existingRegionNames ? existingRegionNames : [];
  }
}
