import { Action, createReducer, on } from '@ngrx/store';

import * as PackageManagersActions from './package-managers.actions';
import { initialState, PackageManagersState } from './package-managers.state';

export const packageManagersFeatureKey = 'packageManagers';

const packageManagersReducer = createReducer<PackageManagersState>(
  initialState,
  on(PackageManagersActions.LoadApproversSuccessAction, (state: PackageManagersState, {payload}) => {
    return {
      ...state,
      approvers: payload
    };
  }),
  on(PackageManagersActions.LoadPrivilegedUsersSuccessAction, (state: PackageManagersState, {payload}) => {
    return {
      ...state,
      privilegedUsers: payload
    };
  }),
  on(PackageManagersActions.CleanUpPackageManagersAction, () => {
    return {
      approvers: [],
      privilegedUsers: []
    };
  })
);

export function PackageManagersReducer(
  state: PackageManagersState | undefined,
  action: Action): PackageManagersState {
  return packageManagersReducer(state, action);
}
