import { Injectable } from '@angular/core';

import { TgNotificationService } from '@bbraun/toolguide';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { NOTIFICATION_DURATION_IN_MS } from '../../modules/shared/constants/notification/notification.constants';
import { UserAssignments } from '../../modules/shared/interfaces/global-user-replacement/user-assignments.interface';
import { LdapUser } from "../../modules/shared/interfaces/ldap-user/ldap-user.interface";
import { LdapUserHttpRequest } from "../../modules/shared/interfaces/ldap-user/ldap-user-http-request";
import { PagingResponse } from "../../modules/shared/interfaces/paging/paging-response.interface";
import { AssignmentsService } from "../../modules/shared/services/assignments/assignments.service";
import { UsersService } from "../../modules/shared/services/users/users.service";
import * as GlobalUserReplacementActions from "../global-user-replacement/global-user-replacement.actions";

@Injectable()
export class GlobalUserReplacementEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly translocoService: TranslocoService,
    private readonly notification: TgNotificationService,
    private readonly userService: UsersService,
    private readonly assignmentsService: AssignmentsService
  ) {}

  getUsers$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.LoadUsersAction),
    map((action) => action.payload),
    switchMap((payload: LdapUserHttpRequest) =>
      this.userService.retrieveUsers(payload).pipe(
        map((users: PagingResponse<LdapUser>) => {
          return GlobalUserReplacementActions.LoadUsersSuccessAction({payload: users});
        }),
        catchError(() => {
          return of(GlobalUserReplacementActions.CleanUpAction());
        })
      )
    )
  ));

  getUserAssignments$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.GetUserAssignmentsAction),
    map((action) => action.payload),
    switchMap((payload: any) =>
      this.userService.getUserAssignments(payload.userName).pipe(
        map((assignments: Array<UserAssignments>) => {
          return GlobalUserReplacementActions.GetUserAssignmentsSuccessAction({payload: assignments});
        }),
        catchError(() => {
          return of(GlobalUserReplacementActions.CleanUpAction());
        })
      )
    )
  ));

  deleteUserAssignments$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.DeleteUserAssignmentsAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.assignmentsService.deleteUserAssignments(payload.userName, payload.removedAssignments).pipe(
        map(() => GlobalUserReplacementActions.DeleteUserAssignmentsSuccessAction()),
        switchMap(() => {
          return [GlobalUserReplacementActions.DeleteUserAssignmentsSuccessAction(),
            GlobalUserReplacementActions.GetUserAssignmentsAction({payload: {userName: payload.userName}})];
        }),
        catchError(() => {
          return of(GlobalUserReplacementActions.CleanUpAction());
        })
      )
    )
  ));

  reassignUserAssignments$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.ReassignUserAssignmentsAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.assignmentsService.reassignUserAssignments(payload.fromUser, payload.toUser, payload.assignments).pipe(
        map(() => GlobalUserReplacementActions.ReassignUserAssignmentsSuccessAction()),
        switchMap(() => {
          return [
            GlobalUserReplacementActions.ReassignUserAssignmentsSuccessAction(),
            GlobalUserReplacementActions.GetUserAssignmentsAction({payload: {userName: payload.fromUser}})
          ];
        }),
        catchError(() => {
          return of(GlobalUserReplacementActions.CleanUpAction());
        })
      )
    )
  ));

  reassignUserAssignmentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.ReassignUserAssignmentsSuccessAction),
    take(1)
  )).subscribe(() => {
    const message = this.translocoService.translate('globalUserReplacement.userReassignedSuccessfullySnackbar');
    this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
  });

  deletedUserAssignmentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalUserReplacementActions.DeleteUserAssignmentsSuccessAction),
    take(1)
  )).subscribe(() => {
    const message = this.translocoService.translate('globalUserReplacement.userDeletedSuccessfullySnackbar');
    this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
  });
}
