import { createAction, props } from '@ngrx/store';

import { Country } from '../../modules/shared/interfaces/country/country.interface';
import { PackageProductFamilyData } from '../../modules/shared/interfaces/product-family/package-product-family-data.interface';
import { GetRegionsPreviewResponse } from '../../modules/shared/interfaces/region/get-regions-preview-response.interface';
import { ImportedRegionsData } from '../../modules/shared/interfaces/region/imported-regions-data.interface';

export const GET_PACKAGES_BY_PRODUCT_FAMILY = '[IMPORT-FROM-PACKAGE] - GET_PACKAGES_BY_PRODUCT_FAMILY';
export const GET_PACKAGES_BY_PRODUCT_FAMILY_SUCCESS = '[IMPORT-FROM-PACKAGE] - GET_PACKAGES_BY_PRODUCT_FAMILY_SUCCESS';
export const GET_IMPORT_PREVIEW = '[IMPORT-FROM-PACKAGE] - GET_IMPORT_PREVIEW';
export const GET_IMPORT_PREVIEW_SUCCESS = '[IMPORT-FROM-PACKAGE] - GET_IMPORT_PREVIEW_SUCCESS';

export const SAVE_IMPORTED_REGIONS_FROM_PACKAGE = '[IMPORT-FROM-PACKAGE] SAVE_IMPORTED_REGIONS_FROM_PACKAGE';
export const SAVE_IMPORTED_REGIONS_FROM_PACKAGE_SUCCESS = '[IMPORT-FROM-PACKAGE] SAVE_IMPORTED_REGIONS_FROM_PACKAGE_SUCCESS';

export const GetPackagesByProductFamilyAction = createAction(
  GET_PACKAGES_BY_PRODUCT_FAMILY,
  props<{ payload: { productFamily: string; packageId: number } }>()
);

export const GetPackagesByProductFamilySuccessAction = createAction(
  GET_PACKAGES_BY_PRODUCT_FAMILY_SUCCESS,
  props<{ packages: Array<PackageProductFamilyData> }>()
);

export const GetImportPreviewAction = createAction(
  GET_IMPORT_PREVIEW,
  props<{ payload: { parentPackageId: number; packageId: number } }>()
);

export const GetImportPreviewSuccessAction = createAction(
  GET_IMPORT_PREVIEW_SUCCESS,
  props<{ regions: Array<GetRegionsPreviewResponse>; unassignedCountries: Array<Country> }>()
);

// Save Region assignments
export const SaveImportedRegionsFromPackageAction = createAction(
  SAVE_IMPORTED_REGIONS_FROM_PACKAGE, props<{
    payload: {
        packageId: number;
        importedRegionsData: Array<ImportedRegionsData>;
    };
}>());

export const SaveImportedRegionsFromPackageSuccessAction = createAction(SAVE_IMPORTED_REGIONS_FROM_PACKAGE_SUCCESS);
