import { Action, createReducer, on } from '@ngrx/store';

import { Country } from '../../../../app/modules/shared/interfaces/country/country.interface';
import { PagingResponseCustomersAutomaticDistributionModel } from '../../../modules/shared/models/paging/paging-response-customers-automatic-distribution.model';
import * as PackageDetailsActions from './package-assignments.actions';
import { initialState, PackageAssignmentsState } from './package-assignments.state';

export const packageAssignmentsFeatureKey = 'packageAssignments';

const packageAssignmentsReducer = createReducer<PackageAssignmentsState>(
  initialState,
  on(PackageDetailsActions.GetRegionsSuccessAction,
    (state: PackageAssignmentsState, {regions, unassignedCountries}) => {
      return {
        ...state,
        regions,
        unassignedCountries
      };
    }),
  on(PackageDetailsActions.GetCustomersSuccessAction, (state: PackageAssignmentsState, { payload}) => {
    return {
      ...state,
      customers: payload
    };
  }),
  on(PackageDetailsActions.GetRegionAssignmentsChanges,
    (state: PackageAssignmentsState, { userAssignmentsChanges }) => {
      return {
        ...state,
        userAssignmentsChanges: [...state.userAssignmentsChanges, ...userAssignmentsChanges]
      };
    }),
  on(PackageDetailsActions.ScrollCustomersSuccess, (state: PackageAssignmentsState, { payload }) => {
    const customers = {...payload};
    customers.content = state.customers.content.concat(payload.content);

    return {
      ...state,
      customers
    };
  }),
  on(PackageDetailsActions.SavePackageAssignmentsToStoreAction,
    (state: PackageAssignmentsState, {userAssignments}) => {
      const assignments = {...userAssignments};
      return {
        ...state,
        userAssignmentsForm: assignments
      };
    }),
  on(PackageDetailsActions.DeleteRegionSuccessAction, (state: PackageAssignmentsState, {regionId}) => {
    const assignments = {...state.userAssignmentsForm};
    for (const assignment in assignments) {
      if (Object.prototype.hasOwnProperty.call(assignments, assignment) &&
            assignments[assignment].regionId === regionId) {
        delete assignments[assignment];
        break;
      }
    }

    const regions = state.regions.filter((region) => region.id !== regionId);
    const userAssignmentsChanges = state.userAssignmentsChanges
      .filter((assignment) => assignment.regionId !== regionId);

    return {
      ...state,
      userAssignmentsForm: assignments,
      regions,
      userAssignmentsChanges
    };
  }),
  on(PackageDetailsActions.UpdateRegions,
    (state: PackageAssignmentsState, { payload }) => {
      return {
        ...state,
        regions: payload
      };
    }),
  on(PackageDetailsActions.CleanUpPackageAssignmentsAction, () => {
    return {
      regions: [],
      unassignedCountries: null,
      regionAssignments: [],
      customers: new PagingResponseCustomersAutomaticDistributionModel(),
      userAssignmentsChanges: [],
      userAssignmentsForm: null
    };
  }),
  on(PackageDetailsActions.CleanUpPackageAssignmentsWithoutUserAssignmentsFormAction,
    (state: PackageAssignmentsState) => {
      return {
        regions: [],
        unassignedCountries: null,
        regionAssignments: [],
        customers: new PagingResponseCustomersAutomaticDistributionModel(),
        userAssignmentsChanges: state.userAssignmentsChanges,
        userAssignmentsForm: state.userAssignmentsForm
      };
    })
);

export function PackageAssignmentsReducer(
  state: PackageAssignmentsState | undefined,
  action: Action): PackageAssignmentsState {
  return packageAssignmentsReducer(state, action);
}

