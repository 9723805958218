import { createAction, props } from '@ngrx/store';

import { Country } from '../../modules/shared/interfaces/country/country.interface';
import { LdapUser } from '../../modules/shared/interfaces/ldap-user/ldap-user.interface';
import { PackageDetailsResponse } from '../../modules/shared/interfaces/package/package-details-response.interface';
import { PackageUpdate } from '../../modules/shared/interfaces/package/package-details-update.interface';
import {
  PackageRevision,
  PackageRevisionDeclineRequest
} from '../../modules/shared/interfaces/package/package-revision.interface';
import { PackageUserRole } from '../../modules/shared/interfaces/package-user-role/package-user-role.interface';

export const DELETE_PACKAGE = '[PACKAGE-DETAILS] DELETE_PACKAGE';
export const DELETE_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] DELETE_PACKAGE_SUCCESS';
export const LOAD_ACTION_ID = '[PACKAGE-DETAILS] GET_ACTION_ID';
export const LOAD_PACKAGE_DETAILS = '[PACKAGE-DETAILS] LOAD_PACKAGE_DETAILS';
export const LOAD_PACKAGE_DETAILS_SUCCESS = '[PACKAGE-DETAILS] LOAD_PACKAGE_DETAILS_SUCCESS';
export const LOAD_ALL_USERS = '[PACKAGE-DETAILS] LOAD_ALL_USERS';
export const LOAD_ALL_USERS_SUCCESS = '[PACKAGE-DETAILS] LOAD_ALL_USERS_SUCCESS';
export const UPDATE_PACKAGE = '[PACKAGE-DETAILS] UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_AND_ASSIGNMENTS = '[PACKAGE-DETAILS] UPDATE_PACKAGE_AND_ASSIGNMENTS';
export const UPDATE_PACKAGE_AND_ASSIGNMENTS_SUCCESS = '[PACKAGE-DETAILS] UPDATE_PACKAGE_AND_ASSIGNMENTS_SUCCESS';
export const SAVE_ASSIGNMENTS_SUCCESS = '[PACKAGE-DETAILS] SAVE_ASSIGNMENTS_SUCCESS';
export const SUBMIT_PACKAGE = '[PACKAGE-DETAILS] SUBMIT_PACKAGE';
export const SUBMIT_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] SUBMIT_PACKAGE_SUCCESS';
export const GET_ASSIGNED_USER_ROLES = '[PACKAGE-DETAILS] GET_ASSIGNED_USER_ROLES';
export const GET_ASSIGNED_USER_ROLES_SUCCESS = '[PACKAGE-DETAILS] GET_ASSIGNED_USER_ROLES_SUCCESS';
export const APPROVE_PACKAGE = '[PACKAGE-DETAILS] APPROVE_PACKAGE';
export const APPROVE_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] APPROVE_PACKAGE_SUCCESS';
export const DECLINE_PACKAGE = '[PACKAGE-DETAILS] DECLINE_PACKAGE';
export const DECLINE_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] DECLINE_PACKAGE_SUCCESS';
export const PUBLISH_PACKAGE = '[PACKAGE-DETAILS] PUBLISH_PACKAGE';
export const PUBLISH_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] PUBLISH_PACKAGE_SUCCESS';
export const UPDATE_DETAILS_COUNTRIES = '[PACKAGE-DETAILS] UPDATE_DETAILS_COUNTRIES';
export const ARCHIVE_PACKAGE = '[PACKAGE-DETAILS] ARCHIVE_PACKAGE';
export const ARCHIVE_PACKAGE_SUCCESS = '[PACKAGE-DETAILS] ARCHIVE_PACKAGE_SUCCESS';
export const CLEANUP_PACKAGE_DETAILS = '[PACKAGE-DETAILS] CLEANUP_PACKAGE_DETAILS';
export const CLEANUP_ALL_PACKAGE_DETAILS = '[PACKAGE-DETAILS] CLEANUP_ALL_PACKAGE_DETAILS';
export const CREATE_REVISION = '[PACKAGE-DETAILS] CREATE_REVISION';
export const CREATE_REVISION_SUCCESS = '[PACKAGE-DETAILS] CREATE_REVISION_SUCCESS';
export const LOAD_PACKAGE_REVISION = '[PACKAGE-DETAILS] LOAD_PACKAGE_REVISION';
export const LOAD_PACKAGE_REVISION_SUCCESS = '[PACKAGE-DETAILS] LOAD_PACKAGE_REVISION_SUCCESS';
export const APPROVE_REVISION = '[PACKAGE-DETAILS] APPROVE_REVISION';
export const APPROVE_REVISION_SUCCESS = '[PACKAGE-DETAILS] APPROVE_REVISION_SUCCESS';
export const REWORK_REVISION = '[PACKAGE-DETAILS] REWORK_REVISION';
export const REWORK_REVISION_SUCCESS = '[PACKAGE-DETAILS] REWORK_REVISION_SUCCESS';
export const DELETE_REVISION = '[PACKAGE-DETAILS] DELETE_REVISION';
export const DELETE_REVISION_SUCCESS = '[PACKAGE-DETAILS] DELETE_REVISION_SUCCESS';
export const DECLINE_REVISION = '[PACKAGE-DETAILS] DECLINE_REVISION';
export const DECLINE_REVISION_SUCCESS = '[PACKAGE-DETAILS] DECLINE_REVISION_SUCCESS';

// Package Details actions
export const DeletePackageAction = createAction(DELETE_PACKAGE, props<{ packageId: number }>());

export const DeletePackageSuccessAction = createAction(DELETE_PACKAGE_SUCCESS);

// Actions for loading metadata for package details screen
export const LoadActionIdAction = createAction(LOAD_ACTION_ID, props<{ actionId: number }>());

export const LoadPackageDetailsAction = createAction(LOAD_PACKAGE_DETAILS, props<{ id: number; actionId: number }>());

export const LoadPackageDetailsSuccessAction = createAction(
  LOAD_PACKAGE_DETAILS_SUCCESS,
  props<{ payload: PackageDetailsResponse }>()
);

// All users actions
export const LoadAllUsersAction = createAction(LOAD_ALL_USERS, props<{ term: string }>());

export const LoadAllUsersSuccessAction = createAction(LOAD_ALL_USERS_SUCCESS, props<{ payload: Array<LdapUser> }>());

// Update package actions
export const UpdatePackageAction = createAction(UPDATE_PACKAGE, props<{
  payload: {
    id: number;
    updatedPackage: PackageUpdate;
  };
}>());

export const UpdatePackageSuccessAction = createAction(UPDATE_PACKAGE_SUCCESS, props<{ statusCode: number }>());

// Two partly update success actions on package update
export const UpdatePackageAndAssignmentsAction = createAction(UPDATE_PACKAGE_AND_ASSIGNMENTS, props<{ payload: {} }>());

export const UpdatePackageAndAssignmentsSuccessAction = createAction(
  UPDATE_PACKAGE_AND_ASSIGNMENTS_SUCCESS,
  props<{ payload: {} }>()
);

export const SaveAssignmentsSuccessAction = createAction(SAVE_ASSIGNMENTS_SUCCESS, props<{ payload: {} }>());

// Submit package actions
export const SubmitPackageAction = createAction(
  SUBMIT_PACKAGE,
  props<{ payload: { id: number; submitted: PackageUpdate } }>()
);

export const SubmitPackageSuccessAction = createAction(SUBMIT_PACKAGE_SUCCESS, props<{ statusCode: number }>());

// Get user role actions
export const GetAssignedUserRolesAction = createAction(GET_ASSIGNED_USER_ROLES, props<{ packageId: number }>());

export const GetAssignedUserRolesSuccessAction = createAction(
  GET_ASSIGNED_USER_ROLES_SUCCESS,
  props<{ payload: Array<PackageUserRole> }>()
);

// Approve package actions
export const ApprovePackageAction = createAction(APPROVE_PACKAGE, props<{ id: number }>());

export const ApprovePackageSuccessAction = createAction(APPROVE_PACKAGE_SUCCESS);

// Decline package actions
export const DeclinePackageAction = createAction(
  DECLINE_PACKAGE,
  props<{ payload: { id: number; reason: string } }>()
);

export const DeclinePackageSuccessAction = createAction(DECLINE_PACKAGE_SUCCESS);

// Publish package actions
export const PublishPackageAction = createAction(PUBLISH_PACKAGE, props<{ packageId: number }>());

export const PublishPackageSuccessAction = createAction(PUBLISH_PACKAGE_SUCCESS);

export const UpdateDetailsCountries = createAction(UPDATE_DETAILS_COUNTRIES, props<{ payload: Array<Country> }>());

// Archive package actions
export const ArchivePackageAction = createAction(
  ARCHIVE_PACKAGE,
  props<{
    payload: {
      packageId: number,
      reason: string
    }
  }>()
);

export const ArchivePackageSuccessAction = createAction(ARCHIVE_PACKAGE_SUCCESS);

// Clean up action
export const CleanupPackageDetailsAction = createAction(CLEANUP_PACKAGE_DETAILS);

// Package revision actions
export const CreateRevisionAction = createAction(
  CREATE_REVISION,
  props<{
    payload: {
      packageId: number,
      revisionUuid: string,
      requestBody: PackageRevision
    }
  }>()
);

export const CreateRevisionSuccessAction = createAction(CREATE_REVISION_SUCCESS);

export const LoadPackageRevisionAction = createAction(
  LOAD_PACKAGE_REVISION,
  props<{
    actionId: number,
    revisionId: number
  }>()
);
export const LoadPackageRevisionSuccessAction = createAction(
  LOAD_PACKAGE_REVISION_SUCCESS,
  props<{ payload: PackageDetailsResponse }>()
);

export const ApproveRevisionAction = createAction(
  APPROVE_REVISION,
  props<{ payload: { revisionId: number, actionId: number } }>()
);

export const ApproveRevisionSuccessAction = createAction(APPROVE_REVISION_SUCCESS);

export const ReworkRevisionAction = createAction(
  REWORK_REVISION,
  props<{
    payload: {
      revisionId: number,
      requestBody: PackageRevision
    }
  }>()
);

export const ReworkRevisionSuccessAction = createAction(REWORK_REVISION_SUCCESS);

export const DeleteRevisionAction = createAction(DELETE_REVISION, props<{ revisionId: number }>());

export const DeleteRevisionSuccessAction = createAction(DELETE_REVISION_SUCCESS);

export const DeclineRevisionAction = createAction(
  DECLINE_REVISION,
  props<{
    payload: {
      revisionId: number,
      actionId: number,
      requestBody: PackageRevisionDeclineRequest
    }
  }>()
);

export const DeclineRevisionSuccessAction = createAction(DECLINE_REVISION_SUCCESS);
