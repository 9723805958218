import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { StorageService } from '../../shared/services/storage/storage.service';
import { INITIAL_REDIRECT_STATE } from '../auth.constants';

@Component({
  selector: 'app-redirect-endpoint',
  templateUrl: './redirect-endpoint.component.html'
})
export class RedirectEndpointComponent implements AfterViewChecked {

  wasNotCalled = true;

  constructor(
        private readonly oauthService: OAuthService,
        private readonly storageService: StorageService,
        private readonly router: Router
  ) {}

  ngAfterViewChecked(): void {
    if (this.oauthService.getIdToken() && this.oauthService.getAccessToken() && this.wasNotCalled) {
      const route = this.storageService.hasItem(INITIAL_REDIRECT_STATE, localStorage) ?
        this.storageService.getItem(INITIAL_REDIRECT_STATE, localStorage) : '/';
      this.wasNotCalled = false;
      this.router.navigateByUrl(route);
    }
  }
}
