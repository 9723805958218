import { Action, createReducer, on } from '@ngrx/store';

import {
  PagingResponseArchivedPackagesModel
} from "../../modules/shared/models/paging/archived-packages-paging-response.model";
import * as ArchiveActions from './archive.actions';
import { ArchiveState, initialState } from './archive.state';

export const archiveFeatureKey = 'archive';

const archiveReducer = createReducer<ArchiveState>(
  initialState,
  on(ArchiveActions.LoadPackagesAction, (state: ArchiveState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ArchiveActions.LoadPackagesSuccessAction, (state: ArchiveState, { payload }) => {
    return {
      ...state,
      archivedPackages: payload,
      loading: false
    };
  }),
  on(ArchiveActions.ScrollArchivedPackagesAction, (state: ArchiveState) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ArchiveActions.ScrollArchivedPackagesSuccessAction, (state: ArchiveState, { payload }) => {
    const archivedPackages = {...payload};
    archivedPackages.content = state.archivedPackages.content.concat(payload.content);

    return {
      ...state,
      archivedPackages,
      loading: false
    };
  }),
  on(ArchiveActions.CleanupPackagesAction, () => {
    return {
      archivedPackages: new PagingResponseArchivedPackagesModel(),
      loading: true
    };
  })
);

export function ArchiveReducer(state: ArchiveState | undefined, action: Action): ArchiveState {
  return archiveReducer(state, action);
}
