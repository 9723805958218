import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TgNotificationService } from '@bbraun/toolguide';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NOTIFICATION_DURATION_IN_MS } from 'src/app/modules/shared/constants/notification/notification.constants';

import { CustomersService } from '../../../modules/shared/services/customers/customers.service';
import { PackagesService } from '../../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../../shared/shared.actions';
import * as PackageDetailsActions from '../package-details.actions';
import * as PackageAssignmentsActions from './package-assignments.actions';

@Injectable()
export class PackageAssignmentsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly packagesService: PackagesService,
    private readonly customersService: CustomersService,
    private readonly router: Router,
    private readonly notification: TgNotificationService,
    private readonly translocoService: TranslocoService
  ) { }

  getRegions$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.GetRegionsAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.getRegions(action.packageId).pipe(
        map((payload: any) => {
          return PackageAssignmentsActions.GetRegionsSuccessAction({
            regions: payload.regions,
            unassignedCountries: payload.unassignedCountries
          });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  createRegion$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.CreateRegionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.createRegion(payload.packageId, payload.region).pipe(
        map(() => PackageAssignmentsActions.GetRegionsAction({payload: {packageId: payload.packageId}})),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  updateRegion$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.UpdateRegionAction),
    map((action) => action.payload),
    mergeMap((payload) =>
      this.packagesService.updateRegionDetails(payload.packageId, payload.regionId, payload.updatedRegion).pipe(
        map(() => {
          return PackageAssignmentsActions.UpdateRegionSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      ))
  ));

  deleteRegion$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.DeleteRegionAction),
    map((action) => action.payload),
    mergeMap((payload) =>
      this.packagesService.deleteRegion(payload.packageId, payload.regionId).pipe(
        mergeMap(() => {
          const message = this.translocoService.translate('editRegion.delete');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          return [
            PackageAssignmentsActions.GetRegionsAction({payload: {packageId: payload.packageId}}),
            PackageAssignmentsActions.DeleteRegionSuccessAction({regionId: payload.regionId})
          ];
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  saveRegionAssignments$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.SaveRegionAssignmentsAction),
    switchMap((action) =>
      this.packagesService.saveAssignments(action.payload.packageId, action.payload.regionAssignments).pipe(
        switchMap(() => {
          if (!action.shouldNotRedirect) {
            this.router.navigateByUrl('');
          }

          if (action.shouldPublish) {
            return [PackageDetailsActions.SaveAssignmentsSuccessAction({payload: null}),
              PackageDetailsActions.PublishPackageAction({packageId: action.payload.packageId})];
          }

          // The regions shall be refreshed after saving assignments.<br>
          // If the LRC is currently added, it is not possible to assigns FSTs.
          return [PackageDetailsActions.SaveAssignmentsSuccessAction({payload: null}),
            PackageAssignmentsActions.GetRegionsAction(
              {payload: {packageId: action.payload.packageId}}
            )];
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.GetCustomersAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.customersService.getCustomersForAutomaticDistribution(payload.term,
        payload.countryCodes,
        payload.page)
        .pipe(map((response) => {
          return PackageAssignmentsActions.GetCustomersSuccessAction({
            payload: response
          });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
        )
    )
  ));

  scrollCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(PackageAssignmentsActions.ScrollCustomersAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.customersService.getCustomersForAutomaticDistribution('', payload.countryCodes, payload.page).pipe(
        map((response) => {
          return PackageAssignmentsActions.ScrollCustomersSuccess({
            payload: response
          });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
