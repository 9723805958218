import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as ErrorAction from './shared.actions';

/**
 * Application wide shared effects.
 */
@Injectable()
export class SharedEffects {

  /**
   * A generic effect that triggers on a GenericErrorAction and shows an error message to the user. If the GenericErrorAction carries
   * a "cleanup" action as a payload this will be dispatched as the effects result. The "cleanup" action can be used to reset some
   * properties of the state back to the desired value.
   *
   * See GenericErrorAction for more details.
   */
  genericError = createEffect(() => this.actions
    .pipe(
      ofType(ErrorAction.GENERIC_ERROR),
      map((action: ErrorAction.GenericErrorAction) => action),
      map((action: ErrorAction.GenericErrorAction) => {
        return action.cleanup ? action.cleanup : { type: 'NO_CLEANUP_ACTION' };
      })));

  constructor(private readonly actions: Actions) { }
}
