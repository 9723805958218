import { PendingPackagesCount } from "../../modules/shared/interfaces/package/pending-packages-count.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { TableDataObject } from '../../modules/shared/interfaces/table-data-object/table-data-object.interface';
import { PagingResponseDataTableObjectModel } from '../../modules/shared/models/paging/paging-response-data-table-objects.model';

export interface InboxState {
  packages: PagingResponse<TableDataObject>;
  pendingPackagesCount: PendingPackagesCount;
  loading: boolean;
}

export const initialState: InboxState = {
  packages: new PagingResponseDataTableObjectModel(),
  pendingPackagesCount: null,
  loading: true
};
