import { createAction, props } from '@ngrx/store';

import { RegionToUpdate } from '../../../app/modules/shared/interfaces/region/region-to-update.interface';
import { RegionDetails } from '../../modules/shared/interfaces/region/region-details.interface';

export const LOAD_REGION_DETAILS = '[REGION-DETAILS] LOAD_REGION_DETAILS';
export const LOAD_REGION_DETAILS_SUCCESS = '[REGION-DETAILS] LOAD_REGION_DETAILS_SUCCESS';
export const UPDATE_REGION_DETAILS = '[REGION-DETAILS] UPDATE_REGION_DETAILS';
export const UPDATE_REGION_DETAILS_SUCCESS = '[REGION-DETAILS] UPDATE_REGION_DETAILS_SUCCESS';

// Get Region details actions
export const LoadRegionDetailsAction = createAction(LOAD_REGION_DETAILS,
  props<{ payload: { packageId: number; regionId: number } }>());
export const LoadRegionDetailsSuccessAction = createAction(LOAD_REGION_DETAILS_SUCCESS,
  props<{ regionDetails: RegionDetails }>()
);

// Update Region details actions
export const UpdateRegionDetailsAction = createAction(
  UPDATE_REGION_DETAILS, props<{ payload: RegionToUpdate }>());
export const UpdateRegionDetailsSuccessAction = createAction(UPDATE_REGION_DETAILS_SUCCESS);
