import { createAction, props } from '@ngrx/store';

import { ArchivedPackage } from "../../modules/shared/interfaces/package/archived-package.interface";
import { PackageHttpRequest } from '../../modules/shared/interfaces/package/package-http-request.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';

export const LOAD_PACKAGES = '[ARCHIVE] LOAD_PACKAGES';
export const LOAD_PACKAGES_SUCCESS = '[ARCHIVE] LOAD_PACKAGES_SUCCESS';
export const SCROLL_ARCHIVED_PACKAGES = '[ARCHIVE] SCROLL_PACKAGES';
export const SCROLL_ARCHIVED_PACKAGES_SUCCESS = '[ARCHIVE] SCROLL_PACKAGES_SUCCESS';
export const CLEANUP_PACKAGES = '[ARCHIVE] CLEAN_PACKAGES';

export const LoadPackagesAction = createAction(LOAD_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const LoadPackagesSuccessAction =
  createAction(LOAD_PACKAGES_SUCCESS, props<{ payload: PagingResponse<ArchivedPackage> }>());

export const ScrollArchivedPackagesAction =
  createAction(SCROLL_ARCHIVED_PACKAGES, props<{ payload: PackageHttpRequest }>());

export const ScrollArchivedPackagesSuccessAction =
  createAction(SCROLL_ARCHIVED_PACKAGES_SUCCESS, props<{ payload: PagingResponse<ArchivedPackage> }>());

export const CleanupPackagesAction = createAction(CLEANUP_PACKAGES);
