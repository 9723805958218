import { Article } from '../../modules/shared/interfaces/article/article';
import { Product } from '../../modules/shared/interfaces/product/product.interface';
import { ProductFamily } from '../../modules/shared/interfaces/product-family/product-family.interface';

export interface NewPackageState {
  productFamily: Array<ProductFamily>;
  products: Array<Product>;
  articles: Array<Article>;
}

export const initialState: NewPackageState = {
  productFamily: [],
  products: [],
  articles: []
};
