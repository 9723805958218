import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TgNotificationService } from '@bbraun/toolguide';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NOTIFICATION_DURATION_IN_MS } from '../../modules/shared/constants/notification/notification.constants';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as ImportRegionsFromPackageActions from './import-from-package.actions';


@Injectable()
export class ImportFromPackageEffects {
  constructor(
        private readonly actions$: Actions,
        private readonly packagesService: PackagesService,
        private readonly translocoService: TranslocoService,
        private readonly notification: TgNotificationService,
        private readonly router: Router
  ) {
  }

  getPackagesByProductFamily$ = createEffect(() => this.actions$.pipe(
    ofType(ImportRegionsFromPackageActions.GetPackagesByProductFamilyAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.getPackagesByProductFamily(action.productFamily, action.packageId).pipe(
        map((payload: any) => {
          return ImportRegionsFromPackageActions.GetPackagesByProductFamilySuccessAction({
            packages: payload
          });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getImportPreview$ = createEffect(() => this.actions$.pipe(
    ofType(ImportRegionsFromPackageActions.GetImportPreviewAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.getImportPreview(action.parentPackageId, action.packageId).pipe(
        map((payload: any) => {
          return ImportRegionsFromPackageActions.GetImportPreviewSuccessAction({
            regions: payload.regions,
            unassignedCountries: payload.unassignedCountries
          });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  saveImportedRegionsFromPackage$ = createEffect(() => this.actions$.pipe(
    ofType(ImportRegionsFromPackageActions.SaveImportedRegionsFromPackageAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.saveImportedRegionsFromPackage(payload.packageId, payload.importedRegionsData).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.regions.update');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          this.router.navigate(['package-details', payload.packageId]);
          return ImportRegionsFromPackageActions.SaveImportedRegionsFromPackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
