import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';
import * as fromRoot from '../app-state';
import {
  CleanupUserInfoAction,
  GetUserInfoAction,
  IsUserInfoAvailable
} from './auth.actions';
import * as fromAuth from './index';

@Injectable()
export class AuthFacade {
  userInfo$: Observable<UserInfo>;
  isUserInfoAvailable$: Observable<boolean>;

  constructor(private readonly store: Store<fromRoot.AppState>) {
    this.userInfo$ = this.store.pipe(select(fromAuth.getUserInfo));
    this.isUserInfoAvailable$ = this.store.pipe(select(fromAuth.isUserInfoAvailable));
  }

  getUserInfo(): void {
    this.store.dispatch(GetUserInfoAction());
  }

  isUserInfoAvailable(): void {
    this.store.dispatch(IsUserInfoAvailable());
  }

  cleanUp(): void {
    this.store.dispatch(CleanupUserInfoAction());
  }
}
