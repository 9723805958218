import { Country } from '../../../modules/shared/interfaces/country/country.interface';
import { FileType } from '../../../modules/shared/interfaces/file-type/file-type.interface';
import { DownloadProgress } from '../../../modules/shared/interfaces/package-file/download-progress.interface';
import {
  PackageFile,
  PackageFileRevision
} from '../../../modules/shared/interfaces/package-file/package-file.interface';
import { UploadProgress } from '../../../modules/shared/interfaces/package-file/upload-progress.interface';

export interface PackageFilesState {
  fileTypes: Array<FileType>;
  filePreview: Blob;
  filesData: Array<PackageFile>;
  revisionFilesData: Array<PackageFileRevision>;
  assignedCountries: Array<Country>;
  uploadProgress: UploadProgress;
  downloadProgress: DownloadProgress;
  fileName: string;
  newFileUploaded: boolean;
  previewFileRevision: Blob;
  fileLocationUrl: string;
}

export const initialState: PackageFilesState = {
  fileTypes: [],
  filePreview: null,
  filesData: [],
  revisionFilesData: [],
  assignedCountries: [],
  uploadProgress: null,
  downloadProgress: null,
  fileName: null,
  newFileUploaded: false,
  previewFileRevision: null,
  fileLocationUrl: null
};
