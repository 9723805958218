import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  httpHeaders: HttpHeaders;
  httpParams: HttpParams;

  constructor(protected http: HttpClient) { }

  /**
   * Performing generic GET http operation
   *
   * @param url - resource location
   */
  protected get<T>(url: string): Observable<T> {
    const observable = this.http
      .get<T>(url, { headers: this.httpHeaders, params: this.httpParams });

    this.clearOptions();
    return observable;
  }

  /**
   * Performing generic POST http operation
   *
   * @param url - resource location
   * @param body - request body
   */
  protected post<T>(url: string, body: object): Observable<T> {
    const observable = this.http
      .post<T>(url, body, { headers: this.httpHeaders, params: this.httpParams });

    this.clearOptions();
    return observable;
  }

  /**
   * Performing generic PUT http operation
   *
   * @param url - resource location
   * @param body - request body
   */
  protected put<T>(url: string, body: object): Observable<T> {
    const observable = this.http
      .put<T>(url, body, { headers: this.httpHeaders, params: this.httpParams });

    this.clearOptions();
    return observable;
  }

  /**
   * Performing generic PATCH http operation
   *
   * @param url - resource location
   * @param body - request body
   */
  protected patch<T>(url: string, body: object): Observable<T> {
    const observable = this.http
      .patch<T>(url, body, { headers: this.httpHeaders, params: this.httpParams });

    this.clearOptions();
    return observable;
  }

  /**
   * Performing generic DELETE http operation
   *
   * @param url  - resource location
   * @param body - request body
   */
  protected delete<T>(url: string, body: any): Observable<T> {
    const observable = this.http
      .delete<T>(url, { headers: this.httpHeaders, params: this.httpParams, body });

    this.clearOptions();
    return observable;
  }

  /**
   * Applying query parameters with builder pattern approach
   *
   * @param params - http query parameters
   */
  protected withParams(params: HttpParams): this {
    this.httpParams = params;
    return this;
  }

  /**
   * Applying request headers with builder pattern approach
   *
   * @param headers
   */
  protected withHeaders(headers: HttpHeaders): this {
    this.httpHeaders = headers;
    return this;
  }

  /**
   * Clearing all request headers and params.
   */
  private clearOptions(): void {
    this.httpHeaders = null;
    this.httpParams = null;
  }

  /**
   * Adding a new request header.
   * In case headers object is not initialized, makes fresh initializaiton.
   * In case value is undefined or null, the header is not added.
   *
   * @param key - name of the header
   * @param value - actual value of the header, can be undefined
   */
  protected appendHeader(key: string, value: any): this {
    if (!this.httpHeaders) {
      this.httpHeaders = new HttpHeaders();
    }
    if (value !== undefined && value !== null) {
      this.httpHeaders = this.httpHeaders.append(key, value.toString());
    }

    return this;
  }

  /**
   * Adding a new request query param.
   * In case query params object is not initialized, makes fresh initializaiton.
   * In case value is undefined or null, the query param is not added.
   *
   * @param key - name of the query param
   * @param value - actual value of the query param, can be undefined
   */
  protected appendParam(key: string, value: any): this {
    if (!this.httpParams) {
      this.httpParams = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    }

    if (value !== undefined && value !== null) {
      this.httpParams = this.httpParams.append(key, value.toString());
    }

    return this;
  }
}
