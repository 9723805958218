import { Country } from '../../modules/shared/interfaces/country/country.interface';
import { PackageProductFamilyData } from '../../modules/shared/interfaces/product-family/package-product-family-data.interface';
import { GetRegionsPreviewResponse } from '../../modules/shared/interfaces/region/get-regions-preview-response.interface';

export interface ImportFromPackageState {
    regions: Array<GetRegionsPreviewResponse>;
    packages: Array<PackageProductFamilyData>;
    unassignedCountries: Array<Country>;
    loading: boolean;
}

export const initialState: ImportFromPackageState = {
  packages: [],
  regions: [],
  unassignedCountries: [],
  loading: false
};
