import { Action, createReducer, on } from '@ngrx/store';

import * as PackageFilesActions from './package-files.actions';
import { initialState, PackageFilesState } from './package-files.state';

export const packageFilesFeatureKey = 'packageFiles';

const packageFilesReducer = createReducer<PackageFilesState>(
  initialState,
  on(PackageFilesActions.LoadFileTypesSuccessAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      fileTypes: payload
    };
  }),
  on(PackageFilesActions.GetAssignedCountriesSuccessAction, (state: PackageFilesState, {countries}) => {
    return {
      ...state,
      assignedCountries: countries
    };
  }),
  on(PackageFilesActions.GetFilePreviewSuccessAction, (state: PackageFilesState, {fileData}) => {
    return {
      ...state,
      filePreview: fileData
    };
  }),
  on(PackageFilesActions.FilePreviewCleanUpAction, (state: PackageFilesState) => {
    return {
      ...state,
      filePreview: null
    };
  }),
  on(PackageFilesActions.UploadFileSuccessAction, (state: PackageFilesState, {filesData}) => {
    return {
      ...state,
      filesData,
      uploadProgress: null,
      newFileUploaded: true
    };
  }),
  on(PackageFilesActions.DeleteFileInRevisionSuccessAction, (state: PackageFilesState, {revisionFilesData}) => {
    return {
      ...state,
      revisionFilesData,
      uploadProgress: null,
      newFileUploaded: false
    };
  }),
  on(PackageFilesActions.UploadFileProgressAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      uploadProgress: {
        loaded: payload.loaded,
        total: payload.total,
        chunkNumber: payload.chunkNumber,
        totalChunks: payload.totalChunks,
        hasError: false
      }
    };
  }),
  on(PackageFilesActions.UploadFileErrorAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        hasError: true,
        errorResponse: payload
      }
    };
  }),
  on(PackageFilesActions.DownloadFileSuccessAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      fileLocationUrl: payload.fileLocationUrl
    };
  }),
  on(PackageFilesActions.DownloadFileProgressAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      downloadProgress: {
        type: payload.type,
        hasError: false,
        httpResponse: payload.httpResponse
      }
    };
  }),
  on(PackageFilesActions.DownloadFileErrorAction, (state: PackageFilesState) => {
    return {
      ...state,
      downloadProgress: {
        ...state.downloadProgress,
        hasError: true
      }
    };
  }),
  on(PackageFilesActions.ReplaceFileSuccessAction, (state: PackageFilesState, {filesData}) => {
    return {
      ...state,
      filesData,
      uploadProgress: null
    };
  }),
  on(PackageFilesActions.ReplaceFileProgressAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      uploadProgress: {
        loaded: payload.loaded,
        total: payload.total,
        chunkNumber: payload.chunkNumber,
        totalChunks: payload.totalChunks,
        hasError: false
      }
    };
  }),
  on(PackageFilesActions.ReplaceFileErrorAction, (state: PackageFilesState) => {
    return {
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        hasError: true
      }
    };
  }),
  on(PackageFilesActions.ReplaceFileInRevisionSuccessAction, (state: PackageFilesState, {filesData}) => {
    return {
      ...state,
      revisionFilesData: filesData,
      uploadProgress: null,
      newFileUploaded: true
    };
  }),
  on(PackageFilesActions.ReplaceFileInRevisionProgressAction, (state: PackageFilesState, {payload}) => {
    return {
      ...state,
      uploadProgress: {
        loaded: payload.loaded,
        total: payload.total,
        chunkNumber: payload.chunkNumber,
        totalChunks: payload.totalChunks,
        hasError: false
      }
    };
  }),
  on(PackageFilesActions.ReplaceFileInRevisionErrorAction, (state: PackageFilesState) => {
    return {
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        hasError: true
      }
    };
  }),
  on(PackageFilesActions.CleanUpPackageFilesAction, (state: PackageFilesState) => {
    return {
      fileTypes: [],
      filePreview: null,
      filesData: [],
      revisionFilesData: [],
      assignedCountries: [],
      uploadProgress: null,
      downloadProgress: null,
      downloadedFile: null,
      fileName: null,
      newFileUploaded: false,
      previewFileRevision: null,
      fileLocationUrl: null
    };
  }),
  on(PackageFilesActions.CreateFileRevisionSuccessAction, (state: PackageFilesState, {revisionFilesData}) => {
    return {
      ...state,
      revisionFilesData,
      uploadProgress: null,
      newFileUploaded: true
    };
  }),
  on(PackageFilesActions.RevertFileRevisionSuccessAction, (state: PackageFilesState, {revisionFilesData}) => {
    return {
      ...state,
      revisionFilesData,
      uploadProgress: null,
      newFileUploaded: false
    };
  }),
  on(PackageFilesActions.PreviewFileRevisionSuccessAction, (state: PackageFilesState, {fileData}) => {
    return {
      ...state,
      previewFileRevision: fileData
    };
  }),
  on(PackageFilesActions.CleanUpPreviewFileRevision, (state: PackageFilesState) => {
    return {
      ...state,
      previewFileRevision: null
    };
  })
);

export function PackageFilesReducer(state: PackageFilesState | undefined, action: Action): PackageFilesState {
  return packageFilesReducer(state, action);
}
