import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ArchivedPackage } from "../../modules/shared/interfaces/package/archived-package.interface";
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as ArchiveActions from './archive.actions';

@Injectable()
export class ArchiveEffects {
  constructor(private readonly actions$: Actions, private readonly packagesService: PackagesService) {
  }

  loadArchivedPackages$ = createEffect(() => this.actions$.pipe(
    ofType(ArchiveActions.LoadPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.getArchivedPackages(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<ArchivedPackage>) => {
          return ArchiveActions.LoadPackagesSuccessAction({payload: packages});
        }),
        catchError(() => {
          return of(ArchiveActions.CleanupPackagesAction());
        })
      )
    )
  ));

  scrollArchivedPackages$ = createEffect(() => this.actions$.pipe(
    ofType(ArchiveActions.ScrollArchivedPackagesAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.getArchivedPackages(payload.page, payload.sort, payload.searchParameter).pipe(
        map((packages: PagingResponse<ArchivedPackage>) => {
          return ArchiveActions.ScrollArchivedPackagesSuccessAction({payload: packages});
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));
}
