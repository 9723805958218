import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ARCHIVE_TABLE, INBOX_TABLE } from '../../constants/table/table.constants';
import { Country } from '../../interfaces/country/country.interface';
import { FstPreview } from '../../interfaces/import-fst/fst-preview.interface';
import { ArchivedPackage } from "../../interfaces/package/archived-package.interface";
import { NewPackage, Package } from '../../interfaces/package/package.interface';
import { PackageDetailsResponse } from '../../interfaces/package/package-details-response.interface';
import { PackageUpdate } from '../../interfaces/package/package-details-update.interface';
import { PendingPackagesCount } from "../../interfaces/package/pending-packages-count.interface";
import { AssignedUserRoles } from "../../interfaces/package-user-role/assigned-user-roles.interface";
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';
import { PackageProductFamilyData } from '../../interfaces/product-family/package-product-family-data.interface';
import { ImportedRegionsData } from '../../interfaces/region/imported-regions-data.interface';
import { Region } from '../../interfaces/region/region.interface';
import {
  AssignmentsForSave,
  RegionAssignmentsUpdate,
  RegionAssignmentsUpdateData
} from '../../interfaces/region/region-assignents-update.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';
import { HttpBaseService } from '../http/http.base.service';

@Injectable({
  providedIn: 'root'
})
export class PackagesService extends HttpBaseService {

  constructor(private readonly http: HttpClient) {
    super();
  }

  get(page: number, sort: string, searchParameter: string): Observable<PagingResponse<Package>> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('size', INBOX_TABLE.size.toString())
      .append('page', page.toString())
      .append('sort', sort.toString())
      .append('searchParameter', searchParameter);

    return this.http.get<PagingResponse<Package>>(`${environment.apiUrl}/packages`, {params});
  }

  /**
   * Get the data for a package by its id and pass an action id from the inbox page.
   *
   * @param id
   * @param actionId
   */
  getSingle(id: number, actionId: number): Observable<PackageDetailsResponse> {
    if (!actionId) {
      return this.http.get<PackageDetailsResponse>(`${environment.apiUrl}/packages/${id}`);
    }

    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('actionId', `${actionId}`);
    return this.http.get<PackageDetailsResponse>(`${environment.apiUrl}/packages/${id}`, {params});
  }

  createPackage(newPackage: NewPackage): Observable<Package> {
    return this.http.post<Package>(`${environment.apiUrl}/packages`, newPackage);
  }

  updatePackage(id: number, updated: PackageUpdate): Observable<any> {
    return this.http.put<PackageUpdate>(`${environment.apiUrl}/packages/${id}`, updated);
  }

  deletePackage(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/packages/${id}`);
  }

  approvePackage(id: number): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/packages/${id}/approve`, null);
  }

  declinePackage(id: number, reason: string): Observable<any> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()}).append('declineReason', reason);

    return this.http.put<any>(`${environment.apiUrl}/packages/${id}/decline`, null, {params});
  }

  publishPackage(packageId: number): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/packages/${packageId}/publish`, null);
  }

  getAssignedCountries(packageId: number): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(`${environment.apiUrl}/packages/${packageId}/assigned-countries`);
  }

  getAssignedUserRoles(id: number): Observable<Array<AssignedUserRoles>> {
    return this.http.get<Array<AssignedUserRoles>>(`${environment.apiUrl}/packages/${id}/assigned-user-roles`);
  }

  /**
   * Get all the regions for a package.
   *
   * @param packageId - Package Ids
   */
  getRegions(packageId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/packages/${packageId}/regions`);
  }

  /**
   * Create a new region to the package
   *
   * @param packageId
   * @param newRegion
   */
  createRegion(packageId: number, newRegion: Region): Observable<Region> {
    return this.http.post<Region>(`${environment.apiUrl}/packages/${packageId}/region`, newRegion);
  }

  /**
   * Save imported region(s) from an existing package
   *
   * @param packageId
   * @param existingRegions
   */
  saveImportedRegionsFromPackage(packageId: number, existingRegions: Array<ImportedRegionsData>):
    Observable<Array<ImportedRegionsData>> {
    return this.http.post<Array<ImportedRegionsData>>(
      `${environment.apiUrl}/packages/${packageId}/regions`,
      existingRegions);
  }

  /**
   * Delete a single region.
   *
   * @param packageId - The ID of the current package.
   * @param regionId  - The ID of the region.
   */
  deleteRegion(packageId: number, regionId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/packages/${packageId}/regions/${regionId}`);
  }

  /**
   * Get data for a single region when it is open for editing
   *
   * @param packageId - The id of the package to be archived.
   * @param regionId  - The ID of the region.
   */
  getRegionDetails(packageId: number, regionId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/packages/${packageId}/regions/${regionId}`);
  }

  /**
   * Get data for  a single region when it is open for editing
   *
   * @param packageId - The id of the package to be archived.
   * @param regionId  - The ID of the region.
   * @param updated
   */
  updateRegionDetails(packageId: number, regionId: number, updated: Region): Observable<any> {
    return this.http.put(`${environment.apiUrl}/packages/${packageId}/regions/${regionId}`, updated);
  }

  /**
   * Gets data for all published packages for a concrete product family
   *
   * @param productFamily
   * @param packageId     - The id of the package to be archived.
   */
  getPackagesByProductFamily(productFamily: string, packageId: number): Observable<Array<PackageProductFamilyData>> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('exclude', packageId.toString());
    return this.http.get<Array<PackageProductFamilyData>>(
      `${environment.apiUrl}/packages/product-family/${productFamily}`,
      {params}
    );
  }

  /**
   * Get the import's preview
   *
   * @param parentPackageId - Id of the parent package
   * @param packageId       - Id of the selected package
   */
  getImportPreview(parentPackageId: number, packageId: number): Observable<any> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('from', `${parentPackageId}`)
      .append('to', `${packageId}`);

    return this.http.get(`${environment.apiUrl}/packages/regions/import-preview`, {params});
  }

  /**
   * Get preview for the uploaded FSTs
   *
   * @param packageId - The id of the package to be archived.
   * @param regionId  - The ID of the region.
   * @param template
   */
  getFstPreview(packageId: number, regionId: number, template: FormData): Observable<Array<FstPreview>> {
    const headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});

    return this.http.post<Array<FstPreview>>(
      `${environment.apiUrl}/packages/${packageId}/regions/${regionId}/fst-import-preview`,
      template,
      {
        ...headers
      }
    );
  }

  /**
   * Save the assignments for a region
   *
   * @param packageId         - The id of the package to be archived.
   * @param regionAssignments - the corresponding assignments for a certain region
   */
  saveAssignments(
    packageId: number,
    regionAssignments: Array<AssignmentsForSave>
  ): Observable<Array<RegionAssignmentsUpdateData>> {
    return this.http.post<Array<RegionAssignmentsUpdate>>(
      `${environment.apiUrl}/packages/${packageId}/regions/user-assignments`,
      regionAssignments
    );
  }

  /**
   * Archives a package.
   *
   * @param packageId     - The id of the package to be archived.
   * @param archiveReason - reason for archiving.
   */
  archivePackage(packageId: number, archiveReason: string): Observable<any> {
    const reason = {archiveReason};
    return this.http.put<any>(`${environment.apiUrl}/packages/${packageId}/archive`, reason);
  }

  /**
   * Retrieves all the archived packages
   *
   *@param searchParameter - (optional) a flag for applying specific searching criteria
   *@param page            - (optional) a flag for the current page of the response
   *@param sort            - (optional) a flag for the current filter for sorting of packages
   */
  getArchivedPackages(page?: number, sort?: string, searchParameter?: string):
    Observable<PagingResponse<ArchivedPackage>> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
      .append('size', ARCHIVE_TABLE.size.toString())
      .append('page', page.toString())
      .append('sort', sort.toString())
      .append('searchParameter', searchParameter);

    return this.http.get<PagingResponse<ArchivedPackage>>(`${environment.apiUrl}/packages/archived`, {params});
  }

  getPendingPackagesCount(): Observable<PendingPackagesCount> {
    return this.http.get<PendingPackagesCount>(`${environment.apiUrl}/packages/pending/count`);
  }
}
