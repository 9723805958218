import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';

import { AuthFacade } from '../../store/auth/auth.facade';
import { authConfig } from './auth.config';
import { authModuleConfig } from './auth-module.config';
import { AuthGuard } from './guard/auth.guard';
import { RedirectEndpointComponent } from './redirect-endpoint/redirect-endpoint.component';

@NgModule({
  imports: [
    OAuthModule.forRoot()
  ],
  declarations: [
    RedirectEndpointComponent
  ],
  exports: [
    RedirectEndpointComponent
  ],
  providers: [
    AuthGuard,
    AuthFacade
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig }
      ]
    };
  }

  constructor (@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
