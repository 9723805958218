import { createAction, props } from '@ngrx/store';

import { Country } from '../../../../app/modules/shared/interfaces/country/country.interface';

export const LOAD_COUNTRIES = '[PACKAGE-REGISTRATIONS] LOAD_COUNTRIES';
export const LOAD_COUNTRIES_SUCCESS = '[PACKAGE-REGISTRATIONS] LOAD_COUNTRIES_SUCCESS';
export const LOAD_REGION_COUNTRIES = '[PACKAGE-REGISTRATIONS] LOAD_REGION_COUNTRIES';
export const LOAD_CE_REGION_COUNTRIES_SUCCESS = '[PACKAGE-REGISTRATIONS] LOAD_CE_REGION_COUNTRIES_SUCCESS';
export const LOAD_FRENCH_BLOCK_COUNTRIES_SUCCESS = '[PACKAGE-REGISTRATIONS] LOAD_FRENCH_BLOCK_COUNTRIES_SUCCESS';
export const CLEANUP_PACKAGE_REGISTRATIONS = '[PACKAGE-REGISTRATIONS] CLEANUP_PACKAGE_REGISTRATIONS';

export const LoadCountriesAction = createAction(LOAD_COUNTRIES, props<{ term: string }>());

export const LoadCountriesSuccessAction = createAction(LOAD_COUNTRIES_SUCCESS, props<{ payload: Array<Country> }>());

export const LoadRegionCountriesAction = createAction(LOAD_REGION_COUNTRIES, props<{ payload: number }>());

export const LoadCERegionCountriesSuccessAction =
  createAction(LOAD_CE_REGION_COUNTRIES_SUCCESS, props<{ countries: Array<Country> }>());

export const LoadFrenchBlockCountriesSuccessAction =
  createAction(LOAD_FRENCH_BLOCK_COUNTRIES_SUCCESS, props<{ countries: Array<Country> }>());

export const CleanUpPackageRegistrationsAction = createAction(CLEANUP_PACKAGE_REGISTRATIONS);
