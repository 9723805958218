import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TgNotificationService } from '@bbraun/toolguide';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, timer } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { RevisionsService } from 'src/app/modules/shared/services/revisions/revisions.service';

import { NOTIFICATION_DURATION_IN_MS } from '../../modules/shared/constants/notification/notification.constants';
import { TabsEnum } from '../../modules/shared/enumerations/tabs.enum';
import { LdapUser } from '../../modules/shared/interfaces/ldap-user/ldap-user.interface';
import { PackageDetailsResponse } from '../../modules/shared/interfaces/package/package-details-response.interface';
import { PackagesService } from '../../modules/shared/services/packages/packages.service';
import { UsersService } from '../../modules/shared/services/users/users.service';
import { GenericErrorAction } from '../shared/shared.actions';
import * as PackageDetailsActions from './package-details.actions';

@Injectable()
export class PackageDetailsEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly packagesService: PackagesService,
    private readonly usersService: UsersService,
    private readonly notification: TgNotificationService,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
    private readonly revisionService: RevisionsService
  ) { }

  loadPackageDetails$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.LoadPackageDetailsAction),
    map((action) => action),
    switchMap((payload) =>
      this.packagesService.getSingle(payload.id, payload.actionId)
        .pipe(
          map((payload: PackageDetailsResponse) => {
            return PackageDetailsActions.LoadPackageDetailsSuccessAction({ payload });
          }),
          catchError((error: Error) => {
            return of(new GenericErrorAction(error));
          }))
    )
  ));

  loadPackageRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.LoadPackageRevisionAction),
    map((action) => action),
    switchMap(payload => {
      return this.revisionService.getPackageRevision(payload.actionId, payload.revisionId)
        .pipe(
          map((payload: PackageDetailsResponse) =>
            PackageDetailsActions.LoadPackageRevisionSuccessAction({ payload })),
          catchError((error: Error) => of(new GenericErrorAction(error)))
        );
    })
  ));

  deletePackageRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.DeleteRevisionAction),
    map((action) => action),
    switchMap((action) =>
      this.revisionService.deletePackageRevision(action.revisionId).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.deleteRevision.success');
          this.router.navigateByUrl('/', { state: { activeTab: TabsEnum.CHANGES } });
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.DeleteRevisionSuccessAction();
        }),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      )
    )
  ));

  loadAllUsers$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.LoadAllUsersAction),
    map((action) => action.term),
    switchMap((term) =>
      this.usersService.getUsers(term).pipe(
        map((users: Array<LdapUser>) => {
          return PackageDetailsActions.LoadAllUsersSuccessAction({ payload: users });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  updatePackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.UpdatePackageAction),
    switchMap((action) =>
      this.packagesService.updatePackage(action.payload.id, action.payload.updatedPackage).pipe(
        switchMap((statusCode: number) => {
          return [PackageDetailsActions.UpdatePackageSuccessAction({ statusCode }),
            PackageDetailsActions.LoadPackageDetailsAction({ id: action.payload.id, actionId: null })];
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  /**
   * This effect listens to success actions:
   * 1. SaveAssignmentsSuccessAction
   * 2. UpdatePackageSuccessAction
   *
   * In case only one of the actions is consumed, the
   * second one will have 'DEFAULT' value meaning it
   * is not triggered or successfully processed.
   *
   * This effect opens a snackbar with different messages
   * based on the actions consumed.
   */
  updatePackageAndAssignments$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.UpdatePackageAndAssignmentsAction),
    switchMap(() => {
      const defaultValue = 'DEFAULT';

      const saveAssignments$ = this.actions$.pipe(
        ofType(PackageDetailsActions.SaveAssignmentsSuccessAction),
        startWith(defaultValue),
        takeUntil(timer(1000))
      );

      const updatePackage$ = this.actions$.pipe(
        ofType(PackageDetailsActions.UpdatePackageSuccessAction),
        startWith(defaultValue),
        takeUntil(timer(1000))
      );

      forkJoin([saveAssignments$, updatePackage$]).subscribe((value) => {
        let message: string;

        if (value[0] === defaultValue && value[1] === defaultValue) {
          return;
        }

        if (value[0] === defaultValue) {
          message = this.translocoService.translate('packageDetails.saved');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          return;
        }

        if (value[1] === defaultValue) {
          message = this.translocoService.translate('packageDetails.regions.update');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          return;
        }

        message = this.translocoService.translate('packageDetails.savedWithAssignments');
        this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
      });

      return of(PackageDetailsActions.UpdatePackageAndAssignmentsSuccessAction({ payload: null }));
    })
  ));

  submitPackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.SubmitPackageAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.updatePackage(payload.id, payload.submitted).pipe(
        map((statusCode: number) => {
          const message = this.translocoService.translate('packageDetails.submit.success');

          this.router.navigateByUrl('');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.SubmitPackageSuccessAction({ statusCode });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  getAssignedUserRoles = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.GetAssignedUserRolesAction),
    map((action) => action.packageId),
    switchMap((packageId) =>
      this.packagesService.getAssignedUserRoles(packageId).pipe(
        map((assignedUserRoles) => {
          return PackageDetailsActions.GetAssignedUserRolesSuccessAction({ payload: assignedUserRoles });
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  approvePackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.ApprovePackageAction),
    switchMap((action) =>
      this.packagesService.approvePackage(action.id).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.approve.success');

          this.router.navigateByUrl('');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.ApprovePackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  declinePackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.DeclinePackageAction),
    map((action) => action.payload),
    switchMap((action) =>
      this.packagesService.declinePackage(action.id, action.reason).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.decline');

          this.router.navigateByUrl('');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.DeclinePackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  publishPackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.PublishPackageAction),
    switchMap((action) =>
      this.packagesService.publishPackage(action.packageId).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.publish.success');

          this.router.navigateByUrl('');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.PublishPackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  deletePackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.DeletePackageAction),
    switchMap((action) =>
      this.packagesService.deletePackage(action.packageId).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.deletePackage.snackbarMessage');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          this.router.navigateByUrl('/');
          return PackageDetailsActions.DeletePackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  archivePackage$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.ArchivePackageAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.packagesService.archivePackage(payload.packageId, payload.reason).pipe(
        map(() => {
          this.router.navigateByUrl('/');
          return PackageDetailsActions.ArchivePackageSuccessAction();
        }),
        catchError((error: Error) => {
          return of(new GenericErrorAction(error));
        })
      )
    )
  ));

  createRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.CreateRevisionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionService.revisionPackage(payload.packageId, payload.revisionUuid, payload.requestBody).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.startedRevision');
          this.router.navigateByUrl('/', { state: { activeTab: TabsEnum.CHANGES } });
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);

          return PackageDetailsActions.CreateRevisionSuccessAction();
        }),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      )
    )
  ));

  approveRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.ApproveRevisionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionService.approveRevision(payload.revisionId, payload.actionId).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.revision.approved');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          this.router.navigateByUrl('/', { state: { activeTab: TabsEnum.CHANGES } });

          return PackageDetailsActions.ApproveRevisionSuccessAction();
        }),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      )
    )
  ));

  reworkRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.ReworkRevisionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionService.reworkRevision(payload.revisionId, payload.requestBody).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.reSubmitPackage.snackbarMessage');

          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          this.router.navigateByUrl('/', { state: { activeTab: TabsEnum.CHANGES } });

          return PackageDetailsActions.ReworkRevisionSuccessAction();
        }),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      )
    )
  ));

  declineRevision$ = createEffect(() => this.actions$.pipe(
    ofType(PackageDetailsActions.DeclineRevisionAction),
    map((action) => action.payload),
    switchMap((payload) =>
      this.revisionService.declineRevision(payload.revisionId, payload.actionId, payload.requestBody).pipe(
        map(() => {
          const message = this.translocoService.translate('packageDetails.revision.declined');
          this.notification.openSnackBar(message, NOTIFICATION_DURATION_IN_MS);
          this.router.navigateByUrl('/', { state: { activeTab: TabsEnum.CHANGES } });

          return PackageDetailsActions.DeclineRevisionSuccessAction();
        }),
        catchError((error: Error) => of(new GenericErrorAction(error)))
      )
    )
  ));
}
