import { createAction, props } from '@ngrx/store';

import { UserAssignments } from '../../modules/shared/interfaces/global-user-replacement/user-assignments.interface';
import {
  UserAssignmentsToChange
} from "../../modules/shared/interfaces/global-user-replacement/user-assignments-to-change.interface";
import { LdapUser } from "../../modules/shared/interfaces/ldap-user/ldap-user.interface";
import { LdapUserHttpRequest } from "../../modules/shared/interfaces/ldap-user/ldap-user-http-request";
import { PagingResponse } from "../../modules/shared/interfaces/paging/paging-response.interface";

export const LOAD_USERS = '[GLOBAL-USER-REPLACEMENT] LOAD_USERS';
export const LOAD_USERS_SUCCESS = '[GLOBAL-USER-REPLACEMENT] LOAD_USERS_SUCCESS';
export const GET_USER_ASSIGNMENTS = '[GLOBAL-USER-REPLACEMENT] GET_USER_ASSIGNMENTS';
export const GET_USER_ASSIGNMENTS_SUCCESS = '[GLOBAL-USER-REPLACEMENT] GET_USER_ASSIGNMENTS_SUCCESS';
export const DELETE_USER_ASSIGNMENTS = '[GLOBAL-USER-REPLACEMENT] DELETE_USER_ASSIGNMENTS';
export const DELETE_USER_ASSIGNMENTS_SUCCESS = '[GLOBAL-USER-REPLACEMENT] DELETE_USER_ASSIGNMENTS_SUCCESS';
export const REASSIGN_USER_ASSIGNMENTS = '[GLOBAL-USER-REPLACEMENT] REASSIGN_USER_ASSIGNMENTS';
export const REASSIGN_USER_ASSIGNMENTS_SUCCESS = '[GLOBAL-USER-REPLACEMENT] REASSIGN_USER_ASSIGNMENTS_SUCCESS';
export const CLEANUP_UP = '[GLOBAL-USER-REPLACEMENT] CLEAN_UP';

export const LoadUsersAction = createAction(LOAD_USERS, props<{ payload: LdapUserHttpRequest }>());

export const LoadUsersSuccessAction = createAction(LOAD_USERS_SUCCESS,
  props<{ payload: PagingResponse<LdapUser> }>()
);

export const GetUserAssignmentsAction = createAction(GET_USER_ASSIGNMENTS, props<{
  payload: {
    userName: string
  }
}>());

export const GetUserAssignmentsSuccessAction =
  createAction(GET_USER_ASSIGNMENTS_SUCCESS, props<{ payload: Array<UserAssignments> }>());

export const DeleteUserAssignmentsAction = createAction(DELETE_USER_ASSIGNMENTS, props<{
  payload: {
    userName: string,
    removedAssignments: Array<UserAssignmentsToChange>
  }
}>());

export const DeleteUserAssignmentsSuccessAction = createAction(DELETE_USER_ASSIGNMENTS_SUCCESS);

export const ReassignUserAssignmentsAction = createAction(REASSIGN_USER_ASSIGNMENTS, props<{
  payload: {
    fromUser: string,
    toUser: string,
    assignments: Array<UserAssignmentsToChange>
  }
}>());

export const ReassignUserAssignmentsSuccessAction = createAction(REASSIGN_USER_ASSIGNMENTS_SUCCESS);

export const CleanUpAction = createAction(CLEANUP_UP);
