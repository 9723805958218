import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { CUSTOMERS_AUTOCOMPLETE_PAGING_PROPERTIES } from '../../constants/autocomplete/autocomplete.constants';
import { CustomerAutomaticDistribution } from '../../interfaces/customer/customer-automatic-distribution';
import { PagingResponse } from '../../interfaces/paging/paging-response.interface';
import { CustomHttpParamEncoderService } from '../custom-http-param-encoder/custom-http-param-encoder.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Retrieves a list of customers which are allowed to have automatic distribution
   *
   * @param customerName
   * @param countryCodes
   * @param page
   */
  getCustomersForAutomaticDistribution(customerName: string, countryCodes: string, page: number):
    Observable<PagingResponse<CustomerAutomaticDistribution>> {
    const headers = new HttpHeaders({
      countryCodes: countryCodes,
      'Content-Type': 'application/json'
    });
    if (!customerName && !page) {
      return this.http.get<PagingResponse<CustomerAutomaticDistribution>>(
        `${environment.apiUrl}/customers/automatic-distribution`,
        {
          headers
        });
    } else {
      const params = new HttpParams({encoder: new CustomHttpParamEncoderService()})
        .append('customerName', customerName ? customerName : '')
        .append('page', CUSTOMERS_AUTOCOMPLETE_PAGING_PROPERTIES.page.toString())
        .append('sort', CUSTOMERS_AUTOCOMPLETE_PAGING_PROPERTIES.sort.toString());

      return this.http.get<PagingResponse<CustomerAutomaticDistribution>>(
        `${environment.apiUrl}/customers/automatic-distribution`,
        {
          headers,
          params
        });
    }
  }
}
