import { AuditExportData } from '../../modules/shared/interfaces/audit-exports/audit-export-data.interface';
import { AuditExportsTablePackage } from '../../modules/shared/interfaces/audit-exports/audit-export-table-package.interface';
import { PagingResponse } from '../../modules/shared/interfaces/paging/paging-response.interface';
import { PagingResponseAuditPackageModel } from '../../modules/shared/models/paging/paging-response-audit-package.model';

export interface AuditExportsState {
  packages: PagingResponse<AuditExportsTablePackage>;
  pdfExport: Array<AuditExportData>;
  loading: boolean;
}

export const initialState: AuditExportsState = {
  packages: new PagingResponseAuditPackageModel(),
  pdfExport: null,
  loading: true
};
