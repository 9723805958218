import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AuthState, initialState } from './auth.state';

export const authFeatureKey = 'auth';

const authReducer = createReducer<AuthState>(
  initialState,
  on(AuthActions.GetUserInfoSuccessAction, (state: AuthState, { payload }) => {
    return {
      ...state,
      userInfo: payload
    };
  }),
  on(AuthActions.CleanupUserInfoAction, (state: AuthState) => {
    return {
      userInfo: null
    };
  })
);

export function AuthReducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
