import { Injectable } from '@angular/core';

import { Observable } from "rxjs";

import { environment } from "../../../../../environments/environment";
import { UserAssignmentsToChange } from "../../interfaces/global-user-replacement/user-assignments-to-change.interface";
import { BaseHttpService } from "../http/base-http.service";

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService extends BaseHttpService {

  /**
   * Deletes selected assignments in the recent packages for the supplied username.
   *
   * @param userName    - 4-2-2 username of the removed user
   * @param requestBody - data for removed package assignments for current user.
   */
  deleteUserAssignments(
    userName: string,
    requestBody: Array<UserAssignmentsToChange>
  ): Observable<Array<UserAssignmentsToChange>> {
    return this.appendParam('from', userName)
      .delete<Array<UserAssignmentsToChange>>(`${environment.apiUrl}/assignments`, requestBody);
  }

  /**
   * Removes all assignments from one user and assigns it to another.
   *
   * @param fromUser    - 4-2-2 of the user we remove the assignments from.
   * @param toUser      - 4-2-2 of the user we assign the assignments to.
   * @param assignments - the actual assignments.
   */
  reassignUserAssignments(
    fromUser: string,
    toUser: string,
    assignments: Array<UserAssignmentsToChange>
  ): Observable<any> {
    return this.appendParam('from', fromUser).appendParam('to', toUser)
      .put<any>(`${environment.apiUrl}/assignments`, assignments);
  }
}
